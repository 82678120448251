<template>
  <v-dialog v-model="showDialog" max-width="1000" class="xs12 md6">
    <v-card>
      <v-card-title>
        <span class="headline">{{
          editMode ? "Benutzer bearbeiten" : "Neuen Benutzer anlegen"
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="saveUser">
          <v-row>
            <v-col cols="12" md="2">
              <v-select
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newUser.salutation"
                :items="salutationOptions"
                label="Anrede"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newUser.forename"
                :rules="nameRules"
                label="Vorname"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newUser.lastname"
                :rules="nameRules"
                label="Nachname"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-select
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newUser.role"
                :items="roleOptions"
                label="Rolle"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newUser.tel"
                :rules="phoneRules"
                label="Telefon"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newUser.mobilePhone"
                :rules="phoneRules"
                label="Handy"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="$store.state.company.softwareIntegration.zvooveOne">
            <v-col cols="12" md="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newUser.config.zvooveOneUuid"
                label="zvoove One Uuid"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newUser.config.zvooveNextLevelApi"
                label="nextLevel API"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newUser.email"
                :rules="emailRules"
                label="E-Mail"
                required
                :disabled="editMode"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            @click="toggleMandants"
            color="primary"
            class="mb-5"
            >Mandanten</v-btn
          >
          <v-row v-if="newUser && mandants && showMandants">
            <v-col
              v-for="mandant in mandants"
              :key="mandant.uuid"
              cols="12"
              md="3"
            >
              <v-checkbox
                :label="mandant.name"
                :value="mandant.uuid"
                v-model="newUser.config.mandants"
                color="primary"
              />
            </v-col>
          </v-row>

          <v-card-actions>
            <div v-if="saveError">{{ saveError }}</div>
            <v-btn @click="closeDialog" color="abort">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="success">Speichern</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { User } from "@/models/user.model";
import { UserService } from "@/services/api/user.service";
import { Salutation } from "@/enums/salutation.model";
import { UserRole } from "@/enums/user-role.enum";
import { Mandant } from "@/models/mandant.model";
import { UserHelperService } from "@/services/user-helper.service";
import ToastService from "@/services/toast.service";

export default defineComponent({
  name: "UserDialog",
  props: {
    show: Boolean,
    editMode: Boolean,
    editUserModel: Object as () => User | null,
    mandants: Array as () => Mandant[],
  },
  data() {
    return {
      emailRules: [
        (v: any) => !!v || "Dieses Feld ist erforderlich",
        (v: any) =>
          /.+@.+\..+/.test(v) || "Geben Sie eine gültige E-Mail-Adresse ein",
      ],
      nameRules: [
        (v: any) => !!v || "Dieses Feld ist erforderlich",
        (v: any) => (v && v.length <= 20) || "Maximal 20 Zeichen",
      ],
      newUser: this.createEmptyUser(), // Using a method to create an empty user
      phoneRules: [
        (v: any) => !!v || "Dieses Feld ist erforderlich",
        (v: any) => (v && v.length <= 15) || "Maximal 15 Zeichen",
      ],
      roleOptions: [
        // { title: "Über Admin", value: UserRole.uberAdmin }, NOT SELECTABLE
        { title: "Admin", value: UserRole.Admin },
        { title: "Lohnauszahlung", value: UserRole.PayrollOfficer },
        { title: "Buchhaltung", value: UserRole.Accountant },
        { title: "Niederlassungsleitung", value: UserRole.BranchManager },
        { title: "Disponent", value: UserRole.Dispatcher },
      ],
      salutationOptions: [
        { title: Salutation[1], value: Salutation.Herr },
        { title: Salutation[2], value: Salutation.Frau },
        { title: Salutation[3], value: Salutation.Divers },
      ],
      saveError: "",
      selectedSalutation: Salutation[1],
      showDialog: this.show,
      showMandants: false,
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    show(newVal) {
      this.showDialog = newVal;
      if (!this.showDialog) this.showMandants = false;
    },
    editUserModel: {
      handler(newVal) {
        if (newVal && this.editMode) {
          this.newUser = { ...newVal };
        } else {
          this.newUser = this.createEmptyUser();
        }
      },
      immediate: true,
    },
  },
  methods: {
    createEmptyUser(): User {
      return {
        email: "",
        forename: "",
        lastname: "",
        mobilePhone: "",
        role: 15,
        salutation: 1,
        tel: "",
        username: "",
        config: {
          aiData: {
            mailStyle: "",
            whatsAppStyle: "",
            mailSignature: "",
          },
          mandants: [],
          zvooveNextLevelApi: "",
          zvooveOneUuid: "",
          dispatcherBoard: {
            columnDemand: {
              filterStatus: [],
              filterMandants: [],
              orderDraggable: [],
            },
            columnCustomer: {
              filterStatus: [],
              filterMandants: [],
              orderDraggable: [],
            },
            columnCandidate: {
              filterStatus: [],
              filterMandants: [],
              orderDraggable: [],
            },
            columnEmployee: {
              filterStatus: [],
              filterMandants: [],
              orderDraggable: [],
            },
          },
          pdHub: {
            password: "",
            token: "",
            tokenValidTill: "",
            username: "",
          },
        },
      } as User;
    },
    toggleMandants() {
      this.showMandants = !this.showMandants;
    },
    closeDialog() {
      this.showDialog = false;
      this.showMandants = false;
      this.$emit("update:show", false); // Emit event to update the prop
    },
    async saveUser() {
      this.saveError = "";

      const validMandantUuids = (this.mandants || []).map(
        (mandant) => mandant.uuid
      );
      this.newUser.config.mandants = this.newUser.config.mandants.filter(
        (uuid: string) => validMandantUuids.includes(uuid)
      );

      try {
        if (!this.editMode) {
          await this.userService.addUser(this.newUser);
        } else {
          await this.userService.editUser(this.newUser).then(() => {
            ToastService.show("Benutzer aktualisiert");
          });
        }

        this.$emit("update:show", false);
        this.showMandants = false;
      } catch (error: any) {
        switch (error.response?.status) {
          case 401:
            this.saveError =
              "Authentifizierungsfehler. Bitte loggen Sie sich neu ein.";
            break;
          case 409:
            this.saveError =
              "User existiert bereits. Bitte geben Sie eine andere Email Adresse ein.";
            break;
          default:
            this.saveError = "Ein unbekannter Fehler ist aufgetreten.";
        }
      }
    },
  },
});
</script>

<style scoped></style>
