import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-739e2d0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(_component_v_icon, {
          size: "small",
          class: "mr-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode("fa-solid fa-link")
          ]),
          _: 1
        }),
        _createTextVNode("Konfiguration für ATS System zvoove Recruit ")
      ]),
      _createVNode(_component_v_form, {
        onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_switch, {
                    color: "primary",
                    modelValue: _ctx.localConfig.softwareIntegration.atsDeterminesStatus,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localConfig.softwareIntegration.atsDeterminesStatus) = $event)),
                    label: "ATS bestimmt Kandidatenstatus"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_switch, {
                    color: "primary",
                    modelValue: _ctx.localConfig.softwareIntegration.atsAutoDocu,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localConfig.softwareIntegration.atsAutoDocu) = $event)),
                    label: "automatische Dokumentation"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localConfig.softwareIntegration.zvooveRecruitLink,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localConfig.softwareIntegration.zvooveRecruitLink) = $event)),
                    label: "zvoove Recruit Link"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localConfig.apiKeys.baseURLZvooveRecruit,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localConfig.apiKeys.baseURLZvooveRecruit) = $event)),
                    label: "Base URL API"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localConfig.apiKeys.zvooveOpenBewerber,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localConfig.apiKeys.zvooveOpenBewerber) = $event)),
                    label: "API OpenBewerber"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    modelValue: _ctx.localConfig.apiKeys.zvooveOpenStelle,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localConfig.apiKeys.zvooveOpenStelle) = $event)),
                    label: "API OpenStelle"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(" Statusnamen im ATS System:")
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider, { class: "mb-5" }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localConfig.statusOptionsRecruit, (statusOption, index) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  cols: "12",
                  md: "4",
                  key: statusOption._id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      variant: _ctx.vStyle.card.variant || undefined,
                      rounded: _ctx.vStyle.card.rounded || undefined,
                      border: _ctx.vStyle.card.border || undefined,
                      class: "mb-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, { class: "d-flex justify-content-between align-items-center mb-3" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(statusOption.text) + " ", 1),
                            _createVNode(_component_v_spacer),
                            _createVNode(_component_v_btn, {
                              variant: "text",
                              onClick: ($event: any) => (_ctx.removeStatusOption(index))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("fa-solid fa-trash-can")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: statusOption.text,
                              "onUpdate:modelValue": ($event: any) => ((statusOption.text) = $event),
                              label: "Anzeige in Dispositioner",
                              density: "compact",
                              class: "mb-2"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: statusOption.value,
                              "onUpdate:modelValue": ($event: any) => ((statusOption.value) = $event),
                              label: "Statusname Recruit",
                              density: "compact",
                              class: "mb-2"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: statusOption.slider,
                              "onUpdate:modelValue": ($event: any) => ((statusOption.slider) = $event),
                              label: "Slider Position",
                              density: "compact",
                              class: "mb-2"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_v_select, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: statusOption.status,
                              "onUpdate:modelValue": ($event: any) => ((statusOption.status) = $event),
                              items: _ctx.linkingStatusOptions,
                              label: "Status",
                              density: "compact",
                              multiple: ""
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue", "items"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["variant", "rounded", "border"])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    variant: _ctx.vStyle.card.variant || undefined,
                    rounded: _ctx.vStyle.card.rounded || undefined,
                    border: _ctx.vStyle.card.border || undefined,
                    class: "border-dashed mb-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, { class: "d-flex justify-content-between align-items-center mb-3" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Neuer Status ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newStatusOption.text,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newStatusOption.text) = $event)),
                            label: "Anzeige in Dispositioner",
                            density: "compact",
                            class: "mb-2"
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newStatusOption.value,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newStatusOption.value) = $event)),
                            label: "Statusname Recruit",
                            density: "compact",
                            class: "mb-2"
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newStatusOption.slider,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newStatusOption.slider) = $event)),
                            label: "Slider Position",
                            density: "compact",
                            class: "mb-2"
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                          _createVNode(_component_v_select, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newStatusOption.status,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.newStatusOption.status) = $event)),
                            items: _ctx.linkingStatusOptions,
                            label: "Status",
                            density: "compact",
                            multiple: ""
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"]),
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            variant: "text",
                            onClick: _ctx.addNewStatusOption
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Hinzufügen")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["variant", "rounded", "border"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            variant: _ctx.vStyle.btn.variant || undefined,
            rounded: _ctx.vStyle.btn.rounded || undefined,
            border: _ctx.vStyle.btn.border || undefined,
            class: "mt-10",
            type: "submit",
            color: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Speichern")
            ]),
            _: 1
          }, 8, ["variant", "rounded", "border"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}