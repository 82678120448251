<!--src/components/header/Header.vue-->
<template>
  <div
    class="d-flex justify-space-between align-end"
    :class="smAndDown ? 'flex-end' : ''"
  >
    <div>
      <v-img
        v-if="!smAndDown"
        class="mx-5 mb-4 mt-4 pd-master"
        :width="mdAndDown ? 50 : 200"
        aspect-ratio="1/1"
        cover
        :src="mdAndDown ? appLogoSmall : appLogo"
        @click="toggleMenu('/dashboard')"
      ></v-img>
      <v-tooltip activator="parent" location="right"
        >zurück zum Dashboard</v-tooltip
      >
    </div>
    <div class="d-flex">
      <div :class="smAndDown ? 'ml-1 d-flex' : 'd-flex'">
        <v-text-field
          width="200"
          :class="smAndDown ? 'mr-2' : 'mr-6'"
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          v-if="showDispositionTabs"
          v-model="searchTerm"
          density="compact"
          label="Suchbegriff"
          @keydown.enter="askColumnsForMatches()"
          clearable
        >
          <v-tooltip activator="parent" location="bottom"
            >Einen oder mehrere Suchbegriffe eingeben und mit Enter
            bestätigen</v-tooltip
          >
        </v-text-field>
        <v-select
          :class="smAndDown ? 'mr-2' : 'mr-10'"
          width="200"
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          v-model="selectedMandants"
          density="compact"
          :items="mandants"
          item-title="branchInitials"
          item-value="uuid"
          label="Eingeloggte Niederlassungen"
          clearable
          multiple
          @update:model-value="handleSelectedMandants(selectedMandants)"
        >
        </v-select>
      </div>
      <ZorstStatus
        v-if="!mdAndDown && $store.state.company.softwareIntegration.zorst"
      ></ZorstStatus>
      <div v-if="!smAndDown">
        <ReportBug></ReportBug>
        <UserProfile></UserProfile>
      </div>
      <div>
        <v-badge
          v-if="globalExpiredReminders.length > 0"
          color="abort"
          :content="globalExpiredReminders.length"
          overlap
          offset-x="0"
        >
          <div>
            <v-icon
              size="small"
              @mouseover="showTooltip"
              @mouseleave="hideTooltip"
            >
              fa-solid fa-bell
            </v-icon>
          </div>
        </v-badge>

        <v-icon v-if="globalExpiredReminders.length === 0" size="small">
          fa-solid fa-bell
        </v-icon>
      </div>
      <v-btn class="ml-4" icon density="compact" variant="text">
        <v-icon class="menu-icon"> fa-solid fa-ellipsis-vertical </v-icon>

        <v-menu activator="parent" offset-y>
          <v-list>
            <v-list-item
              v-for="item in filteredItems"
              :key="item.text"
              class="d-flex"
              @click="toggleMenu(item.route)"
            >
              <template v-slot:prepend>
                <v-icon size="xs">{{ item.icon }}</v-icon>
              </template>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
    </div>
  </div>
  <v-divider
    :thickness="2"
    class="border-opacity-25"
    color="primary"
  ></v-divider>

  <div
    v-if="showRecruitTabs"
    :class="mdAndDown ? 'd-flex justify-space-between' : 'd-flex justify-start'"
  >
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-table-cells"
      @click="selectTab(HeaderTab.JobMatrix)"
      data-tid="recruit-link-jobmatrix"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Jobs Matrix</v-label>
      </template>
      <template v-if="!smAndDown"> Jobanzeigen Matrix </template>
    </v-btn>
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-table-list"
      @click="selectTab(HeaderTab.JobList)"
      data-tid="recruit-link-joblist"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Jobs Liste</v-label>
      </template>
      <template v-if="!smAndDown"> Jobanzeigen Liste </template>
    </v-btn>
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-brands fa-facebook"
      @click="selectTab(HeaderTab.Funnels)"
      data-tid="recruit-link-joblist"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Funnels</v-label>
      </template>
      <template v-if="!smAndDown"> Job Funnel </template>
    </v-btn>
  </div>
  <div
    v-if="showDispositionTabs"
    :class="mdAndDown ? 'd-flex justify-space-between' : 'd-flex justify-start'"
  >
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-envelope-open-text"
      @click="openZvooveRecruitLink()"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Neu</v-label>
      </template>
      <template v-if="!smAndDown"> Bewerbungen ATS </template>
    </v-btn>
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6 '"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-at"
      @click="openZvooveRecruitLink(true)"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Mail</v-label>
      </template>
      <template v-if="!smAndDown"> E-Mails ATS </template>
    </v-btn>
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-people-group"
      @click="selectTab(HeaderTab.DispatcherBoard)"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Dispo</v-label>
      </template>
      <template v-if="!smAndDown">Dispoboard</template>
    </v-btn>
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-calendar-days"
      @click="selectTab(HeaderTab.Calendar)"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Cal</v-label>
      </template>
      <template v-if="!smAndDown"> Kalender </template>
    </v-btn>
  </div>
  <div v-if="showAdministrationTabs" class="d-flex justify-start">
    <v-btn
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-file-lines"
      @click="selectTab(HeaderTab.Documentation)"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">Doku</v-label>
      </template>
      <template v-if="!smAndDown"> Dokumentation </template>
    </v-btn>
    <v-btn
      v-if="softwareIntegration.payFlow"
      :variant="smAndDown ? 'text' : 'outlined'"
      class="text-none text-subtitle-2 mr-2 px-2"
      :class="mdAndDown ? 'ml-2 mt-2' : 'mt-6'"
      :rounded="smAndDown ? undefined : vStyle.input.rounded || undefined"
      :base-color="vStyle.input.baseColor || undefined"
      :color="vStyle.input.color || 'primary'"
      :border="smAndDown ? undefined : 'sm opacity-50'"
      density="compact"
      :width="smAndDown ? undefined : buttonTabSize"
      :size="mdAndDown ? undefined : 'large'"
      prepend-icon="fa-solid fa-coins"
      @click="selectTab(HeaderTab.PayFlowOverview)"
    >
      <template v-if="smAndDown">
        <v-label class="text-caption">PayFlow</v-label>
      </template>
      <template v-if="!smAndDown"> PayFlow Übersicht </template>
    </v-btn>
  </div>
  <!-- <SpinnerElementHeader></SpinnerElementHeader> -->
</template>

<script lang="ts">
//TODO: integrate: import SpinnerElementHeader from "@/components/header/SpinnerElementHeader.vue";
import { defineComponent } from "vue";
import { HeaderTab } from "@/enums/header-tabs.enum";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { MutationType } from "@/enums/vuex-mutationtype.enum";
import { TooltipService } from "@/services/tooltip.service";
import { useDisplay } from "vuetify";
import { UserHelperService } from "@/services/user-helper.service";
import { useRouter } from "vue-router";
import { UserRole } from "@/enums/user-role.enum";
import { UserService } from "@/services/api/user.service";
import debounce from "lodash/debounce";
import moment from "moment";
import ReportBug from "./ReportBug.vue";
import ToastService from "@/services/toast.service";
import UserProfile from "@/components/config/user/UserProfile.vue";
import ZorstStatus from "./ZorstStatus.vue";
import { Routes } from "@/enums/routes.enum";

export default defineComponent({
  name: "PdMasterHeader",
  components: {
    ReportBug,
    //SpinnerElementHeader
    UserProfile,
    ZorstStatus,
  },
  emits: ["tab-selected"],
  setup() {
    const router = useRouter();

    return { router };
  },
  created() {
    this.checkIfStoreHasToInit();
  },

  data() {
    const { smAndDown, mdAndDown } = useDisplay();
    return {
      smAndDown,
      mdAndDown,
      appLogo: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/pdmaster-logo.svg`,
      appLogoSmall: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/pd-logo.svg`,
      buttonTabSize: "250",
      currentMandantUuid: this.$store.state.company.loggedInUser.config
        .loggedInMandants
        ? this.$store.state.company.loggedInUser.config.loggedInMandants
        : [],
      menuItems: [
        {
          text: "Dashboard",
          route: Routes.dashboard,
          icon: "fa-solid fa-tachometer-alt",
        },
        {
          accessRoles: [UserRole.UberAdmin],
          text: "Firmenkunden",
          route: Routes.configCompany,
          icon: "fa-solid fa-building",
        },
        {
          text: "Benutzer",
          route: Routes.configUser,
          icon: "fa-solid fa-user",
        },
        {
          text: "Niederlassungen",
          route: Routes.configMandant,
          icon: "fa-solid fa-map-marker-alt",
        },
        {
          text: "Software & Schnittstellen",
          route: Routes.configApi,
          icon: "fa-solid fa-plug",
        },
        {
          text: "Recurion.ai Einstellungen",
          route: Routes.configAi,
          icon: "fa-solid fa-rocket",
        },
        {
          accessRoles: [UserRole.UberAdmin],
          text: "Backend Logs",
          route: Routes.backendLogs,
          icon: "fa-solid fa-boxes-packing",
        },
        {
          accessRoles: [
            UserRole.UberAdmin,
            UserRole.Admin,
            UserRole.CompanyAdmin,
          ],
          text: "Initialisierung & -bereinigung",
          route: Routes.dataCleaning,
          icon: "fa-solid fa-broom",
        },
        {
          text: "Version",
          route: Routes.version,
          icon: "fa-solid fa-info-circle",
          condition: "showVersion",
        },
        {
          text: "Ausloggen",
          route: Routes.logout,
          icon: "fa-solid fa-sign-out-alt",
        },
      ],
      HeaderTab: HeaderTab,
      selectedMandants: [] as string[],
      searchTerm: "",
      tooltipTimeout: null,
      userRole: UserHelperService.getInstance().getUserRole(),
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      softwareIntegration: "softwareIntegration",
      user: "user",
      mandants: "reducedMandants",
      globalExpiredReminders: "getExpiredLinkingReminders",
      storeHasToInit: "storeHasToInit",
    }),
    filteredItems() {
      return this.menuItems.filter((item) => {
        if (!item.accessRoles) {
          return true;
        }
        return item.accessRoles.includes(this.userRole);
      });
    },
  },
  watch: {
    searchTerm(newValue) {
      if (newValue === "") this.askColumnsForMatches();
    },
  },
  async mounted() {
    this.checkForLoggedInMandants();
  },
  methods: {
    ...mapMutations({
      updateLoggedInMandants: MutationType.updateLoggedinMandants,
      setICM: MutationType.setICM,
    }),
    ...mapActions(["loadStoreInitialData"]),
    askColumnsForMatches() {
      this.setICM({
        message: InterComponentMessage.matchMeFromGlobalSearch,
        payload: {
          searchTerm: this.searchTerm,
        },
      });
    },
    async checkForLoggedInMandants() {
      let loggedInUuids =
        this.$store.state.company.loggedInUser.config.loggedInMandants;
      const user = this.$store.getters.user;
      loggedInUuids = user?.config?.loggedInMandants as string[];

      if (loggedInUuids) {
        this.selectedMandants = loggedInUuids;
      }
    },
    async checkIfStoreHasToInit() {
      if (this.storeHasToInit) {
        await this.loadStoreInitialData().then(() => {
          ToastService.show(
            `Sichere Verbindung zu ${this.$store.state.company.name} hergestellt ...`
          );
        });
      }
    },
    handleSelectedMandants: debounce(function (this: any, newValues: string[]) {
      if (this.$store.state.storeHasToInit) return;
      this.updateLoggedInMandants(newValues);
      this.userService.updateUserConfig(
        this.$store.state.company.loggedInUser.config
      );
    }, 1500),
    openZvooveRecruitLink(mail?: boolean) {
      const baseLink = this.softwareIntegration.zvooveRecruitLink;
      const applications = `${baseLink}/inbox/bw`;
      const email = `${baseLink}/inbox/email`;
      if (mail) {
        //TODO: refactor all this Recruit ATS window dimensions:
        window.open(email, "_blank", "width=800,height=800");
      } else {
        window.open(applications, "_blank", "width=800,height=800"); //Opens a small window that the user do not waste his time in another software
      }
    },
    toggleMenu(route: string) {
      if (route) {
        this.router.push(route);
      }
    },
    selectTab(tabName: any) {
      this.$emit("tab-selected", tabName);
    },
    showTooltip(event: MouseEvent) {
      let htmlContent = this.globalExpiredReminders
        .map((expiredReminder: any) => {
          const formattedDate = moment(
            expiredReminder.event.eventDate[0]
          ).format("DD.MM.YYYY [um] HH:mm");

          const linkedCustomer = expiredReminder.description?.customerName
            ? `bei ${expiredReminder.description.customerName}`
            : "";

          const firstName = expiredReminder.description?.firstName ?? "";
          const lastName = expiredReminder.description?.lastName ?? "";

          const eventDetails = `
        <h4 style="margin-bottom: 0.3rem;">${expiredReminder.message}</h4>
        ${firstName} ${lastName} am ${formattedDate} ${linkedCustomer}</p>
        <p><strong>Abgelaufen seit:</strong> ${expiredReminder.expiredSince}</p>
        <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
      `;

          return eventDetails;
        })
        .join("");

      if (htmlContent) {
        TooltipService.showExpiredEvents(event, htmlContent);
      }
    },

    hideTooltip() {
      const existingTooltip = document.getElementById("custom-tooltip");
      if (existingTooltip) {
        existingTooltip.remove();
      }
    },
  },
  props: {
    showRecruitTabs: Boolean,
    showDispositionTabs: Boolean,
    showAdministrationTabs: Boolean,
  },
});
</script>

<style scoped lang="scss">
@import "./scss/variables.scss";

.pd-master {
  cursor: pointer;
  transition: 1s all ease;
}
.pd-master:hover {
  scale: 1.3;
}
</style>
