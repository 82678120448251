<template>
  <v-dialog
    v-model="showModal"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'"
    class="fill-height"
  >
    <v-card>
      <v-card-title>
        <h1><i class="fa-solid fa-users-viewfinder"></i> JOB Leads</h1>
        manuelle Suche in Anzeigendaten</v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card-title
              >Nach Schlagwörtern im Anzeigentext suchen</v-card-title
            >
            <v-chip-group v-model="localDataForQuery.skills" multiple column>
              <v-chip
                v-for="(skill, index) in localDataForQuery.skills"
                class="mb-2"
                :key="index"
                color="primary"
                closable
                @click:close="removeSkill(index)"
              >
                {{ skill }}
              </v-chip>
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                rounded="pill"
                class="ml-3"
                style="max-width: 12rem"
                density="compact"
                v-model="newSkill"
                @keyup.enter="addSkill"
              ></v-text-field>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <v-card-title>Nach Berufen suchen</v-card-title>
            <v-chip-group v-model="localDataForQuery.tags" multiple column>
              <v-chip
                v-for="(tag, index) in localDataForQuery.tags"
                :key="index"
                color="primary"
                closable
                @click:close="removeTag(index)"
              >
                {{ tag }}
              </v-chip>
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                rounded="pill"
                class="ml-3"
                style="max-width: 12rem"
                density="compact"
                v-model="newTag"
                @keyup.enter="addTag"
              ></v-text-field>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <v-card-title>Nach Karriere Level suchen</v-card-title>
            <v-container class="d-flex">
              <v-icon
                class="mx-3"
                icon="fa-solid fa-person-arrow-down-to-line"
              ></v-icon>
              <v-range-slider
                class="mx-2"
                :max="12"
                :min="0"
                :step="1"
                v-model="careerLevel"
              ></v-range-slider>
              <v-icon class="mx-1" icon="fa-solid fa-user-tie"></v-icon>
            </v-container>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" md="8">
            <v-card-title>Umkreissuche</v-card-title>
            <v-slider
              class="mx-4"
              :max="100"
              :min="0"
              :step="1"
              v-model="localDataForQuery.radius"
              thumb-label
            ></v-slider>
          </v-col>
          <v-col cols="12" md="3">
            <v-card-title>{{ localDataForQuery.radius }} km um</v-card-title>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              label="Postleitzahl"
              density="compact"
              v-model="localDataForQuery.postcode"
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" md="8">
            <v-card-title>Weitere Optionen</v-card-title>
            <v-slider
              class="mx-4"
              :max="360"
              :min="0"
              :step="1"
              v-model="localDataForQuery.period"
              thumb-label
            ></v-slider>
          </v-col>
          <v-col cols="12" md="3">
            <v-card-title>Zeitraum</v-card-title>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              label="Tage"
              density="compact"
              v-model="localDataForQuery.period"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="abort" @click="closeModal">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submitQuery(true)"
          >Suchen & alle ersetzen</v-btn
        >
        <v-btn color="success" @click="submitQuery(false)"
          >Suchen & ergänzen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useDisplay } from "vuetify";
import { DataForJobAdLeadsQuery } from "@/models/external/index-anzeigendaten.model";
import { careerLevel, IaCareerLevel } from "@/enums/anzeigendaten.enum";

export default defineComponent({
  name: "DialogManualAnzeigendatenSearch",
  props: {
    dataForQuery: {
      type: Object as PropType<DataForJobAdLeadsQuery>,
      required: true,
    },
  },
  data() {
    const { smAndDown } = useDisplay();

    return {
      smAndDown,
      showModal: false,
      newSkill: "",
      newTag: "",
      careerLevel: [0, 12],
      localDataForQuery: {} as DataForJobAdLeadsQuery,
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    dataForQuery: {
      handler(newValue: DataForJobAdLeadsQuery) {
        this.localDataForQuery = { ...newValue };
        this.setSliderFromCareerLevel(newValue.careerLevel || []);
      },
      immediate: true,
      deep: true,
    },
    careerLevel: {
      handler() {
        this.localDataForQuery.careerLevel = this.careerLevelIndexNumbers;
      },
      deep: true,
    },
  },
  computed: {
    careerLevelIndexNumbers() {
      const labels: IaCareerLevel[] = [];
      for (let i = this.careerLevel[0]; i <= this.careerLevel[1]; i++) {
        const careerLevelValue = careerLevel[i as keyof typeof careerLevel];
        if (careerLevelValue !== undefined) {
          labels.push(careerLevelValue);
        }
      }
      return labels;
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    addSkill() {
      if (
        this.newSkill &&
        !this.localDataForQuery.skills.includes(this.newSkill)
      ) {
        this.localDataForQuery.skills = [
          ...this.localDataForQuery.skills,
          this.newSkill,
        ];
        this.newSkill = "";
      }
    },
    removeSkill(index: number) {
      this.localDataForQuery.skills = this.localDataForQuery.skills.filter(
        (_, i) => i !== index
      );
    },
    addTag() {
      if (this.newTag && !this.localDataForQuery.tags.includes(this.newTag)) {
        this.localDataForQuery.tags = [
          ...this.localDataForQuery.tags,
          this.newTag,
        ];
        this.newTag = "";
      }
    },
    removeTag(index: number) {
      this.localDataForQuery.tags = this.localDataForQuery.tags.filter(
        (_, i) => i !== index
      );
    },
    submitQuery(replace: boolean) {
      if (replace) {
        this.$emit("searchAndReplace", this.localDataForQuery);
      } else {
        this.$emit("searchAndAdd", this.localDataForQuery);
      }
      this.closeModal();
    },
    setSliderFromCareerLevel(indexLevels: number[]) {
      if (!indexLevels.length) {
        this.careerLevel = [0, 12];
        return;
      }
      const careerLevelToIndex: Record<number, number> = {};
      Object.entries(careerLevel).forEach(([index, enumValue]) => {
        careerLevelToIndex[enumValue] = parseInt(index);
      });

      const sliderValues = indexLevels.map((level) => {
        if (careerLevelToIndex[level] !== undefined) {
          return careerLevelToIndex[level];
        }
        throw new Error(
          `Karrierelevel ${level} ist im Mapping nicht definiert.`
        );
      });

      this.careerLevel = [Math.min(...sliderValues), Math.max(...sliderValues)];
    },
  },
});
</script>

<style scoped>
.button-icon {
  height: 1rem;
  width: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
