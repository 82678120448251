import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_range_slider = _resolveComponent("v-range-slider")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_color_picker = _resolveComponent("v-color-picker")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(_component_v_icon, {
          size: "small",
          class: "mr-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode("fa-solid fa-id-badge")
          ]),
          _: 1
        }),
        _createTextVNode("Profilvorlage ")
      ]),
      _createVNode(_component_v_form, {
        onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    variant: _ctx.vStyle.card.variant || undefined,
                    rounded: _ctx.vStyle.card.rounded || undefined,
                    border: _ctx.vStyle.card.border || undefined,
                    class: "mb-4 px-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_title, { class: "mb-4" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Logos & Links")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Link zu Logo hell",
                                modelValue: _ctx.localConfig.profileTemplate.logo,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localConfig.profileTemplate.logo) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Link zu Logo dunkel",
                                modelValue: _ctx.localConfig.profileTemplate.logoDark,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localConfig.profileTemplate.logoDark) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Button 'weitere Kandidaten' Text",
                                modelValue: _ctx.localConfig.profileTemplate.candidatesButtonText,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localConfig.profileTemplate.candidatesButtonText) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Button Link",
                                modelValue: _ctx.localConfig.profileTemplate.candidatesButtonLink,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localConfig.profileTemplate.candidatesButtonLink) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_title, { class: "mb-4" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Überschriften linker Streifen")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Link zu Logo hell",
                                modelValue: _ctx.localConfig.profileTemplate.titles.background,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.background) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Link zu Logo dunkel",
                                modelValue: _ctx.localConfig.profileTemplate.titles.shiftReadiness,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.shiftReadiness) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Button 'weitere Kandidaten' Text",
                                modelValue: _ctx.localConfig.profileTemplate.titles.licences,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.licences) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Button Link",
                                modelValue: _ctx.localConfig.profileTemplate.titles.contact,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.contact) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_title, { class: "mb-4" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Überschriften Hauptteil")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Skills",
                                modelValue: _ctx.localConfig.profileTemplate.titles.skills,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.skills) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Soft Skills",
                                modelValue: _ctx.localConfig.profileTemplate.titles.softSkills,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.softSkills) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Schul- und Ausbildung",
                                modelValue: _ctx.localConfig.profileTemplate.titles.education,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.education) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                density: "compact",
                                label: "Werdegang",
                                modelValue: _ctx.localConfig.profileTemplate.titles.cv,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localConfig.profileTemplate.titles.cv) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_title, { class: "mb-10" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Aufteilung der Seite")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_range_slider, {
                        modelValue: _ctx.widthTblMid,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.widthTblMid) = $event)),
                        step: "1",
                        "thumb-label": "always"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["variant", "rounded", "border"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    variant: _ctx.vStyle.card.variant || undefined,
                    rounded: _ctx.vStyle.card.rounded || undefined,
                    border: _ctx.vStyle.card.border || undefined,
                    class: "mb-4 px-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Primärfarbe")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_color_picker, {
                        modelValue: _ctx.localConfig.profileTemplate.colorPrimary,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localConfig.profileTemplate.colorPrimary) = $event)),
                        modes: ['hex'],
                        elevation: "0"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["variant", "rounded", "border"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    variant: _ctx.vStyle.card.variant || undefined,
                    rounded: _ctx.vStyle.card.rounded || undefined,
                    border: _ctx.vStyle.card.border || undefined,
                    class: "mb-4 px-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Sekundärfarbe")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_color_picker, {
                        modelValue: _ctx.localConfig.profileTemplate.colorSecondary,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.localConfig.profileTemplate.colorSecondary) = $event)),
                        modes: ['hex'],
                        elevation: "0"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["variant", "rounded", "border"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    variant: _ctx.vStyle.card.variant || undefined,
                    rounded: _ctx.vStyle.card.rounded || undefined,
                    border: _ctx.vStyle.card.border || undefined,
                    class: "mb-4 px-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("linker Streifen")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_color_picker, {
                        modelValue: _ctx.localConfig.profileTemplate.background,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.localConfig.profileTemplate.background) = $event)),
                        modes: ['hex'],
                        elevation: "0"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["variant", "rounded", "border"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    variant: _ctx.vStyle.card.variant || undefined,
                    rounded: _ctx.vStyle.card.rounded || undefined,
                    border: _ctx.vStyle.card.border || undefined,
                    class: "mb-4 px-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Schriftfarbe Hell")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_color_picker, {
                        modelValue: _ctx.localConfig.profileTemplate.fontLight,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.localConfig.profileTemplate.fontLight) = $event)),
                        modes: ['hex'],
                        elevation: "0"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["variant", "rounded", "border"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            variant: _ctx.vStyle.btn.variant || undefined,
            rounded: _ctx.vStyle.btn.rounded || undefined,
            border: _ctx.vStyle.btn.border || undefined,
            class: "mt-10",
            type: "submit",
            color: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Speichern")
            ]),
            _: 1
          }, 8, ["variant", "rounded", "border"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}