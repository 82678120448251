import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    variant: _ctx.vStyle.card.variant || undefined,
    rounded: _ctx.vStyle.card.rounded || undefined,
    border: _ctx.vStyle.card.border || undefined
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.cardTitle), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_subtitle, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.cardSubtitle), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            variant: "outlined",
            rounded: _ctx.vStyle.btn.rounded || undefined,
            border: _ctx.vStyle.btn.border || undefined,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getPdHubUserToken()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          }, 8, ["rounded", "border"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["variant", "rounded", "border"]))
}