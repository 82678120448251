// main.ts
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/de.es";
import { Api } from "./services/api/api.class";
import longpress from "./directives/longpress";
import CKEditor from "@ckeditor/ckeditor5-vue";
import vuetify from "@/plugins/vuetify";

let app: any;
store
  .dispatch("loadConfig")
  .then(() => {
    init(app);
  })
  .catch((error) => {
    console.debug("Failed to load config:", error);
    init(app);
  });

function init(app: any) {
  const api = Api.getInstance();
  api.setInterceptor();
  app = createApp(App).use(vuetify).use(store).use(CKEditor).use(router);
  app.directive("longpress", longpress);
  app.mount("#app");
}
