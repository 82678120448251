import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3cdc07a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-end" }
const _hoisted_2 = { id: "feedTable" }
const _hoisted_3 = { style: {"min-width":"13rem"} }
const _hoisted_4 = { class: "d-flex justify-space-between" }
const _hoisted_5 = { style: {"min-width":"8rem"} }
const _hoisted_6 = { class: "d-flex justify-space-between" }
const _hoisted_7 = { style: {"min-width":"11rem"} }
const _hoisted_8 = { class: "d-flex justify-space-between" }
const _hoisted_9 = { style: {"min-width":"13rem"} }
const _hoisted_10 = { class: "d-flex justify-space-between" }
const _hoisted_11 = { style: {"min-width":"13rem"} }
const _hoisted_12 = { class: "d-flex justify-space-between" }
const _hoisted_13 = { class: "d-flex justify-space-between" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_select, {
        variant: _ctx.vStyle.input.variant || undefined,
        rounded: _ctx.vStyle.input.rounded || undefined,
        "base-color": _ctx.vStyle.input.baseColor || undefined,
        color: _ctx.vStyle.input.color || undefined,
        modelValue: _ctx.filters.mandants,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.mandants) = $event)),
          _ctx.applyFilters
        ],
        label: "Niederlassungen auswählen",
        items: _ctx.mandants,
        "item-title": "name",
        "item-value": "uuid",
        "max-width": "20rem",
        multiple: ""
      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items", "onUpdate:modelValue"]),
      (_ctx.isAnyFilterActive)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            class: "ml-5",
            size: "xlarge",
            density: "compact",
            variant: "text",
            icon: "",
            onClick: _ctx.resetFilters
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode("fa-solid fa-filter-circle-xmark")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tooltip, {
                activator: "parent",
                location: "top left"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Alle Filter zurücksetzen")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_v_card, {
      variant: _ctx.vStyle.card.variant || undefined,
      rounded: _ctx.vStyle.card.rounded || undefined,
      border: _ctx.vStyle.card.border || undefined
    }, {
      default: _withCtx(() => [
        _createElementVNode("table", _hoisted_2, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createTextVNode(" Disponent "),
                  _createVNode(_component_v_menu, { "close-on-content-click": false }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        icon: "",
                        density: "compact",
                        variant: "text"
                      }, props, {
                        color: _ctx.filters.user.length > 0 ? 'tertiary' : 'primary'
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, { size: "xsmall" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.filters.user.length > 0
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        variant: _ctx.vStyle.card.variant || undefined,
                        rounded: _ctx.vStyle.card.rounded || undefined,
                        border: _ctx.vStyle.card.border || undefined
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "mb-5" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Disponent auswählen")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userOptions, (user) => {
                                return (_openBlock(), _createBlock(_component_v_checkbox, {
                                  key: user,
                                  modelValue: _ctx.filters.user,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.filters.user) = $event),
                                  label: user,
                                  value: user,
                                  variant: "outlined",
                                  density: "compact",
                                  onChange: _ctx.applyFilters
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "value", "onChange"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["variant", "rounded", "border"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("th", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createTextVNode(" Datum "),
                  _createVNode(_component_v_menu, { "close-on-content-click": false }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        icon: "",
                        density: "compact",
                        variant: "text"
                      }, props, {
                        color: _ctx.filters.endDate ? 'tertiary' : 'primary'
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, { size: "xsmall" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.filters.startDate || _ctx.filters.endDate
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        variant: _ctx.vStyle.card.variant || undefined,
                        rounded: _ctx.vStyle.card.rounded || undefined,
                        border: _ctx.vStyle.card.border || undefined
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "mb-5" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Zeitraum anzeigen")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.filters.startDate,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.startDate) = $event)),
                                label: "Von",
                                density: "compact",
                                type: "date",
                                onChange: _ctx.applyFilters
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onChange"]),
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.filters.endDate,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.endDate) = $event)),
                                label: "Bis",
                                density: "compact",
                                type: "date",
                                onChange: _ctx.applyFilters
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onChange"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["variant", "rounded", "border"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("th", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createTextVNode(" Typ "),
                  _createVNode(_component_v_menu, { "close-on-content-click": false }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        icon: "",
                        density: "compact",
                        variant: "text"
                      }, props, {
                        color: _ctx.filters.type.length > 0 ? 'tertiary' : 'primary'
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, { size: "xsmall" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.filters.type.length > 0
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        variant: _ctx.vStyle.card.variant || undefined,
                        rounded: _ctx.vStyle.card.rounded || undefined,
                        border: _ctx.vStyle.card.border || undefined
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "mb-5" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Nach Art der Notiz filtern")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeOptions, (type) => {
                                return (_openBlock(), _createBlock(_component_v_checkbox, {
                                  key: type,
                                  modelValue: _ctx.filters.type,
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.filters.type) = $event),
                                  label: type,
                                  value: type,
                                  variant: "outlined",
                                  density: "compact",
                                  onChange: _ctx.applyFilters
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "value", "onChange"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["variant", "rounded", "border"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("th", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createTextVNode(" Unternehmen "),
                  _createVNode(_component_v_menu, { "close-on-content-click": false }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        icon: "",
                        density: "compact",
                        variant: "text"
                      }, props, {
                        color: _ctx.filters.customer ? 'tertiary' : 'primary'
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, { size: "xsmall" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.filters.customer
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        variant: _ctx.vStyle.card.variant || undefined,
                        rounded: _ctx.vStyle.card.rounded || undefined,
                        border: _ctx.vStyle.card.border || undefined
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "mb-5" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Nach Unternehmen filtern")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.filters.customer,
                                "onUpdate:modelValue": [
                                  _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filters.customer) = $event)),
                                  _ctx.applyFilters
                                ],
                                label: "Suchbegriff",
                                density: "compact"
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["variant", "rounded", "border"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("th", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createTextVNode(" Personal "),
                  _createVNode(_component_v_menu, { "close-on-content-click": false }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        icon: "",
                        density: "compact",
                        variant: "text"
                      }, props, {
                        color: _ctx.filters.participant ? 'tertiary' : 'primary'
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, { size: "xsmall" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.filters.participant
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        variant: _ctx.vStyle.card.variant || undefined,
                        rounded: _ctx.vStyle.card.rounded || undefined,
                        border: _ctx.vStyle.card.border || undefined
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "mb-5" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Nach Kandidat / Mitarbeiter filtern")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.filters.participant,
                                "onUpdate:modelValue": [
                                  _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filters.participant) = $event)),
                                  _ctx.applyFilters
                                ],
                                label: "Suchbegriff",
                                density: "compact"
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["variant", "rounded", "border"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("th", null, [
                _createElementVNode("div", _hoisted_13, [
                  _createTextVNode(" Notiz "),
                  _createVNode(_component_v_menu, { "close-on-content-click": false }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        icon: "",
                        density: "compact",
                        variant: "text"
                      }, props, {
                        color: _ctx.filters.note ? 'tertiary' : 'primary'
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, { size: "xsmall" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.filters.note
                            ? "fa-solid fa-filter-circle-xmark"
                            : "fas fa-filter"), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        variant: _ctx.vStyle.card.variant || undefined,
                        rounded: _ctx.vStyle.card.rounded || undefined,
                        border: _ctx.vStyle.card.border || undefined
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "mb-5" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Nach Notiz filtern")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                color: _ctx.vStyle.input.color || undefined,
                                modelValue: _ctx.filters.note,
                                "onUpdate:modelValue": [
                                  _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filters.note) = $event)),
                                  _ctx.applyFilters
                                ],
                                label: "Suchbegriff",
                                density: "compact"
                              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["variant", "rounded", "border"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTimelines, (entry, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, _toDisplayString(entry.user), 1),
                _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(entry.entryDate)), 1),
                _createElementVNode("td", null, _toDisplayString(entry.entryType), 1),
                _createElementVNode("td", null, [
                  _createTextVNode(_toDisplayString(entry.customer.name) + " ", 1),
                  (entry.customer.contact)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, " Ansprechpartner: " + _toDisplayString(entry.customer.contact), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("td", null, _toDisplayString(entry.participant.name), 1),
                _createElementVNode("td", {
                  innerHTML: _ctx.formatNoteText(entry?.note)
                }, null, 8, _hoisted_15)
              ]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["variant", "rounded", "border"])
  ]))
}