<template>
  <v-dialog
    v-model="showDialog"
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xl)'"
    class="xs12 md6"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          editMode ? "Niederlassung bearbeiten" : "Neue Niederlassung anlegen"
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="saveMandant">
          <v-row noGutters>
            <v-col cols="12" md="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newMandant.uuid"
                label="Uuid"
                :disabled="editMode"
                required
                class="ma-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newMandant.name"
                :rules="nameRules"
                label="Name"
                required
                class="ma-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :md="softwareIntegration.pdHub ? '2' : '3'">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newMandant.branchNumber"
                label="NL Nr."
                required
                class="ma-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :md="softwareIntegration.pdHub ? '2' : '3'">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newMandant.branchInitials"
                label="Initialen"
                required
                class="ma-1"
              ></v-text-field>
            </v-col>
            <v-col v-if="softwareIntegration.pdHub" cols="12" md="2">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newMandant.mandantL1"
                label="Mandant L1"
                class="ma-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newMandant.whatsApp"
                :rules="phoneRules"
                label="WhatsApp"
                required
                class="ma-1"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-subtitle
            class="my-3"
            v-if="$store.state.company.softwareIntegration.zvooveRecruit"
            >Konfiguration ATS Recruit:</v-card-subtitle
          >
          <v-row
            noGutters
            v-if="$store.state.company.softwareIntegration.zvooveRecruit"
          >
            <v-col cols="12" md="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newMandant.zvoovename"
                :rules="nameRules"
                label="Name Mandant im ATS"
                required
                class="ma-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newMandant.EmailEingangskontoId"
                label="EmailEingangskontoId"
                required
                class="ma-1"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="5">
              <v-textarea
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newMandant.contact"
                label="Kontakt (Html)"
                rows="8"
                required
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="7">
              <v-card-subtitle class="mb-5"
                >Konfiguration Email Server</v-card-subtitle
              >
              <v-btn
                class="ml-2"
                @click="addMailserverConfig()"
                v-if="!newMandant.mailServer"
                ><v-icon>fas fa-plus</v-icon>Mailserver Hinzufügen</v-btn
              >
              <div v-if="newMandant.mailServer" class="d-flex">
                <EmailServerConfigForm :modelValue="newMandant.mailServer" />
                <v-btn icon variant="text" @click="removeMailserverConfig()"
                  ><v-icon>fa-solid fa-trash-can</v-icon></v-btn
                >
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-card-subtitle
                >zugeordnete Postleitzahlen für Bewerbungszuordnung von
                Landingpage</v-card-subtitle
              >
              <v-chip-group column>
                <v-chip
                  v-for="(postcode, index) in newMandant.postalcodes"
                  color="primary"
                  :key="index"
                  close
                  @click:close="removePostcode(index)"
                  @click="openEditPostcodeModal(index)"
                >
                  {{ postcode }}
                  <v-icon
                    small
                    @click.stop="removePostcode(index)"
                    class="ml-2"
                  >
                    fa-solid fa-times
                  </v-icon>
                </v-chip>
                <v-icon
                  @click="openAddPostcodeModal"
                  color="primary"
                  class="mt-2"
                >
                  fa-solid fa-plus
                </v-icon>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-card-actions>
            <div v-if="saveError">{{ saveError }}</div>
            <v-btn @click="closeDialog" color="abort">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="success">Speichern</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Modal for Adding/Editing Postcode -->
  <v-dialog
    v-model="showPostcodeModal"
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          editPostcodeIndex !== null
            ? "Postleitzahl bearbeiten"
            : "Neue Postleitzahl hinzufügen"
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="postcodeForm">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="postcodeInput"
            :rules="postcodeRules"
            label="Postleitzahl"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="editPostcodeIndex !== null"
          @click="closePostcodeModal"
          color="abort"
          >Abbrechen</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="savePostcode" color="success">Speichern</v-btn>
      </v-card-actions>
      <v-container v-if="editPostcodeIndex === null">
        <v-divider></v-divider>
        <v-card-title>oder Umkreissuche</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="postcodeInputCenter"
                :rules="postcodeRules"
                label="zentrale Postleitzahl"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="zipcodeRadius"
                label="Radius in km"
                :rules="radiusRules"
                required
              ></v-text-field>
              <v-label> {{ zipcodeRadius }} kilometer </v-label>
            </v-col>
          </v-row>
          <v-slider
            v-model="zipcodeRadius"
            :max="zipcodeMax"
            :min="zipcodeMin"
            :step="1"
            thumb-label
            class="align-center"
            hide-details
          ></v-slider>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closePostcodeModal" color="abort">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="searchPostcodes" color="primary">Suchen</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Mandant } from "@/models/mandant.model";
import { MandantService } from "@/services/api/mandant.service";
import { mapGetters } from "vuex";
import { useDisplay } from "vuetify";
import { ZipCodeService } from "@/services/api/zip-code.service";
import DialogService from "@/services/dialog.service";
import EmailServerConfigForm from "@/components/forms/email-server-config.component.vue";
import { MailServer } from "../../../models/mailserver.model";

export default defineComponent({
  name: "MandantDialog",
  components: {
    EmailServerConfigForm,
  },
  props: {
    show: Boolean,
    editMode: Boolean,
    editMandantModel: Object as () => Mandant | null,
    allMandants: Object as PropType<Mandant[]>,
  },
  computed: {
    ...mapGetters(["getEnv", "softwareIntegration"]),
  },
  data() {
    const { smAndDown } = useDisplay();
    const radiusMax = parseInt(this.$store.getters.getEnv.VUE_APP_RADIUS_MAX);
    return {
      smAndDown,
      editPostcodeIndex: null as number | null,
      form: null as any,
      mandantsService: new MandantService(),
      nameRules: [
        (v: any) => !!v || "Dieses Feld ist erforderlich",
        (v: any) => (v && v.length <= 50) || "Maximal 50 Zeichen",
      ],
      newMandant: {
        contact: "",
        emailSenderAddress: "",
        EmailEingangskontoId: "",
        name: "",
        postalcodes: [],
        mailServer: {} as MailServer,
        uuid: "",
        whatsApp: "",
        zvoovename: "",
      } as Mandant,
      phoneRules: [
        (v: any) => !!v || "Dieses Feld ist erforderlich",
        (v: any) => (v && v.length <= 20) || "Maximal 20 Zeichen",
        (v: any) =>
          /^[+]?[0-9]{1,3}?[ ]?[-./0-9]*$/.test(v) ||
          "Bitte eine gültige Telefonnummer eingeben",
      ],
      postcodeInput: "",
      postcodeInputCenter: "",
      postcodeRules: [
        (v: any) =>
          /^\d{4,5}$/.test(v) ||
          "Die Postleitzahl muss eine Zahl mit 4 bis 5 Stellen sein.",
      ],
      saveError: "",
      showDialog: false,
      showPostcodeModal: false,
      radiusRules: [
        (v: number) => !!v || "Radius muss eingeben sein",
        (v: number) => (v && v >= 1) || "mindestens 1 km",
        (v: number) => (v && v <= radiusMax) || `maximal ${radiusMax} km`,
      ],
      vStyle: this.$store.state.vStyle,
      zipcodeMax: 50,
      zipcodeMin: 0,
      zipcodeRadius: 15,
      zipCodeService: new ZipCodeService(),
    };
  },
  watch: {
    show(newVal) {
      this.showDialog = newVal;
      if (this.form) this.form.reset();
    },
    editMandantModel(newVal) {
      if (newVal && this.editMode) {
        this.newMandant = { ...newVal };
      }
      if (newVal && !this.editMode) {
        this.newMandant = {
          contact: "",
          EmailEingangskontoId: "",
          mailServer: {} as MailServer,
          name: "",
          postalcodes: [],
          uuid: "",
          whatsApp: "",
          zvoovename: "",
        } as Mandant;
      }
    },
  },
  methods: {
    addMailserverConfig() {
      this.newMandant.mailServer = {
        from: "",
        password: "",
        port: 0,
        secure: false,
        sender: "",
        server: "",
        username: "",
      };
    },
    closeDialog() {
      this.showDialog = false;
      this.$emit("update:show", false); // Emit event to update the prop
    },
    openAddPostcodeModal() {
      this.postcodeInput = "";
      this.editPostcodeIndex = null;
      this.showPostcodeModal = true;
    },
    openEditPostcodeModal(index: number) {
      this.postcodeInput = this.newMandant.postalcodes[index];
      this.editPostcodeIndex = index;
      this.showPostcodeModal = true;
    },
    closePostcodeModal() {
      this.showPostcodeModal = false;
    },
    removeMailserverConfig() {
      this.newMandant.mailServer = undefined;
    },
    savePostcode() {
      if ((this.$refs.postcodeForm as any).validate()) {
        const assignedPostcodes: { name: string; postcodes: string[] }[] = [];

        if (this.allMandants) {
          const isAssigned = this.listExistingPostcodesToMandant(
            this.allMandants,
            this.postcodeInput,
            assignedPostcodes
          );

          if (isAssigned) {
            this.showAlertForExistingPostcodes(assignedPostcodes);
          } else {
            if (this.editPostcodeIndex !== null) {
              this.newMandant.postalcodes[this.editPostcodeIndex] =
                this.postcodeInput;
            } else {
              this.newMandant.postalcodes.push(this.postcodeInput);
            }
            this.showPostcodeModal = false;
          }
        }
      }
    },
    async searchPostcodes() {
      const isValidPostcode = this.postcodeRules.every(
        (rule) => rule(this.postcodeInputCenter) === true
      );

      if (!isValidPostcode) {
        DialogService.alert(
          "Die zentrale Postleitzahl muss eine Zahl mit 4 bis 5 Stellen sein."
        );
        return;
      }
      const postcodes = await this.zipCodeService.getZipcodesWithinRadius(
        this.postcodeInputCenter,
        this.zipcodeRadius
      );

      if (postcodes.length > 0 && this.allMandants) {
        const newPostcodes: string[] = [];
        const assignedPostcodes: { name: string; postcodes: string[] }[] = [];

        postcodes.forEach((postcode: any) => {
          if (this.allMandants) {
            const isAssigned = this.listExistingPostcodesToMandant(
              this.allMandants,
              postcode,
              assignedPostcodes
            );
            if (!isAssigned) {
              newPostcodes.push(postcode);
            }
          }
        });

        this.showAlertForExistingPostcodes(assignedPostcodes);
        this.newMandant.postalcodes = [
          ...this.newMandant.postalcodes,
          ...newPostcodes,
        ];
        this.showPostcodeModal = false;
      }
    },
    checkExistingPostcodes(
      mandants: Mandant[],
      postcode: string
    ): { name: string; postcodes: string[] } | null {
      for (const mandant of mandants) {
        if (mandant.postalcodes && mandant.postalcodes.includes(postcode)) {
          return {
            name: mandant.name,
            postcodes: [postcode],
          };
        }
      }
      return null;
    },
    listExistingPostcodesToMandant(
      mandants: Mandant[],
      postcode: string,
      assignedPostcodes: { name: string; postcodes: string[] }[]
    ): boolean {
      let isAssigned = false;
      mandants.forEach((mandant) => {
        if (mandant.postalcodes && mandant.postalcodes.includes(postcode)) {
          isAssigned = true;
          const existingMandant = assignedPostcodes.find(
            (item) => item.name === mandant.name
          );
          if (existingMandant) {
            existingMandant.postcodes.push(postcode);
          } else {
            assignedPostcodes.push({
              name: mandant.name,
              postcodes: [postcode],
            });
          }
        }
      });
      return isAssigned;
    },
    showAlertForExistingPostcodes(
      assignedPostcodes: { name: string; postcodes: string[] }[]
    ) {
      if (assignedPostcodes.length > 0) {
        const alertMessage = assignedPostcodes
          .map((mandant) => `${mandant.name}: ${mandant.postcodes.join(", ")}`)
          .join("! ");
        DialogService.alert(
          `Bereits vorhandene Postleitzahlen: ${alertMessage}!`
        );
      }
    },
    async saveMandant() {
      this.saveError = "";
      if (this.$refs.form && (this.$refs.form as any).validate()) {
        try {
          if (!this.editMode)
            await this.mandantsService.addMandant(this.newMandant);
          else await this.mandantsService.editMandant(this.newMandant);

          this.$emit("update:show", false);
          (this.$refs.form as any).reset();
        } catch (error: any) {
          switch (error.response.status) {
            case 401:
              this.saveError =
                "Authentifizierungsfehler. Bitte loggen Sie sich neu ein.";
              break;
            case 409:
              this.saveError =
                "User existiert bereits. Bitte geben Sie eine andere Email Adresse ein.";
              break;
            default:
          }
        }
      }
    },
    removePostcode(index: number) {
      if (this.newMandant.postalcodes.length > 1) {
        this.newMandant.postalcodes.splice(index, 1);
      } else {
        this.$emit("error", "Mindestens eine Postleitzahl ist erforderlich.");
      }
    },
  },
});
</script>

<style scoped>
.minus-icon {
  margin-top: -1rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
