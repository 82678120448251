// store/store.ts

import {
  DateSelectable,
  EventType,
  Role,
  LinkingStatus,
} from "@/enums/dependency.enum";
import { JobposterState } from "@/enums/jobposterstate.enum";
import {
  CustomerStates,
  getCustomerStates,
} from "@/enums/customer-states.enum";
import {
  createStore,
  useStore as baseUseStore,
  Store as VuexStore,
} from "vuex";
import { CustomerList } from "@/models/customer.model";
import { ZvooveDashboardData } from "@/models/external/zvoove-one.model";
import { EmployeeList } from "@/models/employee.model";
import { CustomerService } from "@/services/api/customer.service";
import { MandantService } from "@/services/api/mandant.service";
import { EmployeeService } from "@/services/api/employee.service";
import {
  EmployeeStates,
  getEmployeeStates,
} from "@/enums/employee-states.enum";
import { CompanyConfigService } from "@/services/api/company-config.service";
import { DemandStates, getDemandStates } from "@/enums/demand-states.enum";
import { UserService } from "@/services/api/user.service";
import { Mandant } from "@/models/mandant.model";
import { InjectionKey } from "vue";
import {
  ExpiredReminder,
  Linking,
  LinkingCore,
  LinkingDescription,
} from "@/models/linking.model";
import { CandidateService } from "@/services/api/candidate.service";
import { LinkingService } from "@/services/api/linking.service";
import {
  SalaryAdjustment,
  WorkTimePrompt,
} from "@/models/company-config.model";
import moment from "moment";

const candidateRejectedByCustomer = "Absage Firma";
const candidateNotShownUp = "Vorstellungstermin geplatzt";
const interviewCompleted = "Vorstellungstermin durchgeführt";
const applicationRejection = "Absage von uns";
let candidateSuggestionDescription = "Profil verschickt";

export interface State {
  config: Record<string, any>;
}
export const key: InjectionKey<VuexStore<State>> = Symbol();

// Recursively merge objects and remove `_id` fields
function recursiveMerge(target: any, source: any): Promise<void> {
  return new Promise((resolve) => {
    for (const key of Object.keys(source)) {
      if (
        source[key] &&
        typeof source[key] === "object" &&
        !Array.isArray(source[key])
      ) {
        if (!target[key]) {
          target[key] = {};
        }
        recursiveMerge(target[key], source[key]);
      } else if (Array.isArray(source[key])) {
        if (!Array.isArray(target[key])) {
          target[key] = [];
        }

        const targetArray = target[key];
        const sourceArray = source[key];

        sourceArray.forEach((sourceItem: any) => {
          if (typeof sourceItem === "object") {
            const index = targetArray.findIndex((targetItem: any) => {
              if (sourceItem && sourceItem.reminderFor) {
                return targetItem.reminderFor === sourceItem.reminderFor;
              }
            });

            if (index > -1) {
              targetArray[index] = { ...targetArray[index], ...sourceItem };
            } else {
              targetArray.push(sourceItem);
            }
          } else {
            targetArray.push(sourceItem);
          }
        });
      } else {
        target[key] = source[key];
      }
    }
    resolve();
  });
}

export const initialState = {
  vStyle: {
    card: {
      variant: "outlined",
      rounded: "lg",
      border: "md",
    },
    boardItem: {
      variant: "",
      rounded: "lg",
      border: "md",
      elevation: "0",
    },
    input: {
      variant: "outlined",
      rounded: "lg",
      baseColor: "primary",
      color: "primary-darken-1",
    },
    btn: {
      variant: "",
      rounded: "lg",
      border: "primary-darken-1 md",
    },
  },
  storeHasToInit: true, // when is initialized the loadStoreInitialData() actions sets it: false
  zorstAlive: false,
  company: {
    name: "",
    domain: "",
    loggedInUser: {
      role: 15,
      company: "",
      config: {
        aiData: {
          mailStyle: "",
          whatsAppStyle: "",
          mailSignature: "",
        },
        mandants: [] as any[],
        loggedInMandants: [] as any[],
        dispatcherBoard: {
          columnDemand: {
            filterStatus: [] as string[],
            filterMandants: [] as string[],
            orderDraggable: [] as string[],
          },
          columnCustomer: {
            filterStatus: [] as string[],
            filterMandants: [] as string[],
            orderDraggable: [] as string[],
          },
          columnCandidate: {
            filterStatus: [] as string[],
            filterMandants: [] as string[],
            orderDraggable: [] as string[],
          },
          columnEmployee: {
            filterStatus: [] as string[],
            filterMandants: [] as string[],
            orderDraggable: [] as string[],
          },
        },
      },
      email: "",
      forename: "",
      lastname: "",
      mobilePhone: "",
      salutation: "",
      tel: "",
      zvooveNextLevelApi: "",
      zvooveOneUuid: "",
    },
    mandants: [
      {
        uuid: "INIT-Data-UUID",
        name: "Mandanten nicht geladen",
        zvoovename: "Mandanten nicht geladen",
        branchNumber: "11",
        branchInitials: "Error",
        contact: "Error",
        whatsApp: "Error",
        EmailEingangskontoId: "Error",
        plzs: [],
      },
    ],
    // From company config:
    mailServer: {
      server: "",
      port: "",
      username: "",
      password: "",
      from: "",
    },
    salaryAdjustments: [] as SalaryAdjustment[],
    softwareIntegration: {
      atsAutoDocu: false,
      atsDeterminesStatus: false,
      dashboardAiKpiAnalysis: false,
      erpAutoDocu: false,
      indexAnzeigendaten: false,
      indexJobAdAgeLimitDays: 90,
      indexJobAdRefreshInterval: 14,
      indexJobLeadsAgeLimitDays: 30,
      indexJobLeadsMaxAds: 100,
      payFlow: false,
      pdHub: false,
      lastAutoUpdateFromErp: "",
      whatsApp: false,
      workTime: false,
      wordPressPlugin: false,
      zorst: false,
      zvooveOne: false,
      zvooveOneLink: "",
      zvooveRecruit: false,
      zvooveRecruitLink: "",
    },
    apiKeys: {
      baseURLIndexAds: "", //Proxy to: https://db.advertsdata.com/rest_api/post_anz_filter_search_v4.cfm?user=ad_api
      baseURLIndexAuth: "",
      baseUrlIndexCustomers: "", //Proxy to: https://db.advertsdata.com/rest_api/post_company_filter_search_v3.cfm?user=ad_api
      baseURLZvooveOne: "",
      baseURLZvooveRecruit: "",
      indexAnzeigendaten: [
        {
          creditalsForUsersOnly: ["all"],
          creditalsForMandantsOnly: ["all"],
          EXPIRE: "Store noch loaded",
          TOKEN: "",
          LOGIN: "",
        },
      ],
      pdHubBaseUrl: "",
      pdHubClientId: "",
      pdHubClientSecret: "",
      pdHubCustomerId: "",
      pdHubL1Mandant: "",
      pdHubToken: {
        username: "",
        password: "",
        token: "",
        tokenValidTill: "",
      },
      wpPlugin: "",
      wpPluginBaseUrl: "",
      xCoreClientIdZvooveOne: "",
      zvooveOpenBewerber: "",
      zvooveOpenStelle: "",
    },
    statusOptionsRecruit: [
      {
        text: "Eingang",
        value: "Neu [Test]",
        slider: 0,
        status: [
          LinkingStatus.inboxApplication,
          LinkingStatus.interviewCanceled,
          LinkingStatus.interviewNotShownUp,
        ],
      },
      {
        text: "Terminiert",
        value: "Terminiert [Test]",
        slider: 1,
        status: [LinkingStatus.interview],
      },
      {
        text: "Disposition",
        value: "Disposition [Test]",
        slider: 2,
        status: [LinkingStatus.interviewCompleted],
      },
      {
        text: "Vermittlung",
        value: "Vermittlungskandidaten",
        slider: 3,
        status: [LinkingStatus.personnelPlacement],
      },
      {
        text: "Pool",
        value: "Pool [Test]",
        slider: 4,
        status: [LinkingStatus.pool],
      },
      {
        text: "Absage von Bewerber [Testumgebung]",
        value: "Absage Kandidat [Test]",
        status: [LinkingStatus.rejectedCandidate],
      },
      {
        text: "Absage [Testumgebung]",
        value: "Absage PDL [Test]",
        status: [LinkingStatus.rejected],
      },
      {
        text: "Vertragstermin",
        value: "Vertragsangebot",
        status: [LinkingStatus.contractDate],
      },
      {
        text: "Eingestellt [Testumgebung]",
        value: "Eingestellt [Test]",
        status: [LinkingStatus.hired],
      },
    ],
    candidateStatusColor: {
      noneProfile: "#c23a3a", //company admin can set
      openProfile: "#e09900",
      interviewExternal: "#eef103",
      trailWorkExternal: "#bbbe18",
      jobofferExternal: "#18be34",
      jobofferAcceptedExternal: "#33ff00",
      interview: "#00bfa2",
      interviewCanceled: "#244578",
      interviewNotShownUp: "#eb8c2f",
      interviewCompleted: "#a6a6a6",
      pool: "#13acdc",
      rejected: "#ff3131",
    },
    timelineEntryTypes: {
      phoneCallCandidate: {
        description: "Telefonat Kandidat", //company admin can set
        artZvoove: "TM", //company admin can set
      },
      phoneCallCustomer: {
        description: "Telefonat Firma",
        artZvoove: "TM",
      },
      phoneCallEmployee: {
        description: "Telefonat Mitarbeiter",
        artZvoove: "TM",
      },
      emailCandidate: {
        description: "E-Mail Kandidat",
        artZvoove: "MA",
      },
      eMailCustomer: {
        description: "E-Mail Firma",
        artZvoove: "MA",
      },
      eMailEmployee: {
        description: "E-Mail Mitarbeiter",
        artZvoove: "MA",
      },
      whatsAppCandidate: {
        description: "WhatsApp Kandidat",
        artZvoove: "WA",
      },
      whatsAppEmployee: {
        description: "WhatsApp Mitarbeiter",
        artZvoove: "WA",
      },
      note: {
        description: "Notiz",
        artZvoove: "NA",
      },
      addition: {
        description: "Nachtrag",
        artZvoove: "NA",
      },
      meetingOutside: {
        description: "Außendienstbesuch",
        artZvoove: "AD",
      },
      meetingInhouse: {
        description: "Gespräch i. d. Geschäftsstelle",
        artZvoove: "GM",
      },
      candidateSuggestion: {
        description: candidateSuggestionDescription,
        artZvoove: "KV",
      },
    },
    reminderSettings: [
      {
        reminderFor: LinkingStatus.followUp,
        hoursAfter: 0,
        daysAfter: 0,
        showPrompt: false,
        needToContact: true,
        buttonSuccess: "Anrufen",
        message: `Wiedervorlage`,
      },
      {
        reminderFor: LinkingStatus.followUpPrio,
        hoursAfter: 0,
        daysAfter: 0,
        showPrompt: true,
        needToContact: true,
        buttonSuccess: "Anrufen",
        message: `❗Wiedervorlage`,
      },
      {
        reminderFor: LinkingStatus.openProfile,
        hoursAfter: 0,
        daysAfter: 7,
        showPrompt: false,
        needToContact: true,
        buttonResult: "Ergebnis eintragen",
        buttonFailure: "schließen",
        message: `Profil ist noch offen`,
      },
      {
        reminderFor: LinkingStatus.interviewExternal,
        hoursAfter: 2,
        daysAfter: 0,
        showPrompt: true,
        buttonResult: "Ergebnis eintragen",
        buttonFailure: "nicht erschienen",
        needToContact: true,
        message: "Wie lief das Vorstellungespräch beim Kunden?",
      },
      {
        reminderFor: LinkingStatus.trailWorkExternal,
        hoursAfter: 5,
        daysAfter: 0,
        showPrompt: true,
        buttonResult: "Ergebnis eintragen",
        buttonFailure: "nicht erschienen",
        needToContact: true,
        message: "Wie lief das Probearbeiten beim Kunden?",
      },
      {
        reminderFor: LinkingStatus.interview,
        hoursAfter: 1,
        daysAfter: 0,
        showPrompt: true,
        buttonSuccess: "Ja",
        buttonFailure: "Nein",
        needToContact: true,
        message: "Ist der Kandidat erschienen?",
      },
      {
        reminderFor: LinkingStatus.interviewCanceled,
        hoursAfter: 0,
        daysAfter: 7,
        showPrompt: false,
        needToContact: true,
        buttonResult: "Ergebnis eintragen",
        buttonFailure: "schließen",
        message: "VT Abgesagt: Alternativtermin ausmachen!",
      },
      {
        reminderFor: LinkingStatus.interviewNotShownUp,
        hoursAfter: 0,
        daysAfter: 7,
        showPrompt: false,
        needToContact: true,
        buttonResult: "Ergebnis eintragen",
        buttonFailure: "schließen",
        message: "VT geplatzt: Alternativtermin ausmachen!",
      },
    ],
    aiData: {
      template: {
        jobAd: {
          Bezeichnung: "",
          StellenzielHeader: "",
          Stellenziel: "",
          AufgabenHeader: "",
          Aufgaben: "",
          PerspektivenHeader: "",
          Perspektiven: "",
          UnternehmensbedeutungHeader: "",
          Unternehmensbedeutung: "",
          FachlicheAnforderungenHeader: "",
          FachlicheAnforderungen: "",
          PersoenlicheAnforderungenHeader: "",
          PersoenlicheAnforderungen: "",
          ArbeitgeberleistungHeader: "",
          Arbeitgeberleistung: "",
        },
        mail: [
          {
            candidateStatus: LinkingStatus.interview,
            subject: "Einladung zum Vorstellungstermin",
            text1: "wir laden Sie zum Vorstellungstermin ein.",
            text2: "Ich freue mich sehr Sie kennenzulernen.",
          },
          {
            candidateStatus: LinkingStatus.interviewCanceled,
            subject: "Neuer Termin?",
            text1: "sie hatten Ihren Vorstellungstermin bei uns abgesagt.",
            text2: "Können wir einen neuen Termin vereinbaren?",
          },
          {
            candidateStatus: LinkingStatus.interviewNotShownUp,
            subject: "Schade, Termin verpasst ...",
            text1:
              "sie hatten Ihren Vorstellungstermin verpasst und vergessen uns abzusagen.",
            text2: "Können wir einen neuen Termin vereinbaren?",
          },
          {
            candidateStatus: LinkingStatus.interviewCompleted,
            subject: "Vielen Dank für Ihre Zeit",
            text1: "danke für die Einblicke beim Ihrem Vorstellungstermin.",
            text2: "Wir suchen Ihnen eine Stelle nach Ihren Wünschen.",
          },
          {
            candidateStatus: LinkingStatus.personnelPlacement,
            subject: "Wir sind beindruckt von Ihren Qualifikationen",
            text1: "wir sehen Sie als Vermittlungskandidat.",
            text2: "Und suchen Ihnen einen Arbeitgeber der wirklich passt.",
          },
          {
            candidateStatus: LinkingStatus.contractDate,
            subject: "Vertragstermin",
            text1: "wir möchten Sie zum Vertragstermin einladen:",
            text2: "Bringen Sie mit:<br>SteuerId<br>Sozialversicherungsnummer",
          },
          {
            candidateStatus: LinkingStatus.hired,
            subject: "Herzlich Willkommen",
            text1: "wir möchten Sie als Teil unseres Teams begrüßen.",
            text2: "Und heißen Sie herzlich willkommen bei uns.",
          },
          {
            candidateStatus: LinkingStatus.pool,
            subject: "Aufnahme in den Bewerberpool",
            text1: "leider haben wir derzeit kein konkretes Arbeitsangebot.",
            text2: "Wir nehmen Sie aber gerne in den Bewerberpool auf.",
          },
          {
            candidateStatus: LinkingStatus.rejected,
            subject: "Absage zu Ihrer Bewerbung",
            text1: "leider müssen wir Ihre Bewerbung absagen.",
            text2: "Bewerben Sie sich jederzeit auf andere Stellen bei uns.",
          },
          {
            candidateStatus: LinkingStatus.rejectedCandidate,
            subject: "Wir wünschen Ihnen alle Gute",
            text1: "vielen Dank für Ihre rechtzeitige Absage bei uns",
            text2: "und wir wünschen Ihnen alles Gute für Ihren Neustart.",
          },
          {
            candidateStatus: LinkingStatus.rejectedUnattractive,
            subject: "Absage zu Ihrer Bewerbung",
            text1: "leider können wir kein Visum für Sie beantragen!",
            text2: "Das ist für Zeitarbeit in Deutschland nicht möglich.",
          },
        ],
      },
      prompt: {
        company: {
          mailCandidate:
            "Du schreibst eine Email an einen Kandidaten, den wir gerne an einen Kunden vermitteln würden",
          mailCustomer:
            "Du schreibst eine Email an ein Unternehmen and das wir gerne Kandidaten als Zeitarbeiter verleihen würden oder gegen Honarar vermitteln würden",
          mailEmployee:
            "Du schreibst eine Email an unseren Mitarbeiter, der als Zeitarbeiter bei einem Kunden eingesetzt ist!",
          tagsCandidate:
            "Schreib mir die 4 bis maximal 7 konkreten Berufsbezeichnungen oder Jobs für die der Kandidat in Frage käme. Wichtig: Benutze nur die deutschen Berufsbezeichnungen und gibt als möglichst kurzen Stichpunkt aus!",
          tagsCompany:
            "Schreib mir die alle konkreten Berufsbezeichnungen oder Jobs die bei dieser Firma arbeiten können. Wichtig: Benutze nur die deutschen Berufsbezeichnungen auch wenn die in den Anzeigen englisch sind und gibt diese als möglichst kurzen Stichpunkt aus!",
          weAre:
            "Wir sind ein Personaldienstleister und suchen passende Mitarbeiter für Kundenunternehmen, die wir dann entweder im Rahmen der Direktvermittlung an den Kunden vermitteln oder in dem wir die Bewerber selbst einstellen und im Rahmen der Arbeitnehmerüberlassung im Kundenbetrieb einsetzen.",
          weAreShort:
            "Wir sind ein Personaldienstleister und suchen passende Mitarbeiter für Kunden, die wir dann direkt vermitteln oder als Zeitarbeiter verleihen!",
          whatsAppCandidate:
            "Du schreibst eine WhatsApp an einen Kandidaten, für den wir gerne einen Job finden würden.",
          whatsAppCustomer:
            "Du schreibst eine WhatsApp an einen Ansprechpartner von einem Kundenunternehmen",
          whatsAppEmployee:
            "Du schreibst eine WhatsApp-Nachricht an unseren Mitarbeiter, der als Zeitarbeiter bei einem Kunden eingesetzt ist!",
        },
      },
      languages: [
        "Englisch",
        "Türkisch",
        "Polnisch",
        "Russisch",
        "Arabisch",
        "Rumänisch",
        "Bulgarisch",
        "Spanisch",
        "Französisch",
        "Italienisch",
        "Portugiesisch",
        "Chinesisch (Mandarin)",
        "Hindi",
        "Persisch (Farsi)",
        "Serbisch",
        "Bosnisch",
        "Kroatisch",
        "Albanisch",
      ],
      workTimePrompts: [] as WorkTimePrompt[],
    },
    profileTemplate: {
      candidatesButtonLink: "",
      candidatesButtonText: "weitere Kandidaten",
      fontXS: "0.6rem",
      fontS: "0.8rem",
      fontM: "0.9rem",
      logo: "http://assets.dispositioner.de/dispositioner-logo-light.svg",
      logoDark: "http://assets.dispositioner.de/dispositioner-logo-dark.svg",
      tblLeft: "1%",
      tblMid: "24%",
      tblRight: "75%;",
      titles: {
        background: "HINTERGRUND",
        shiftReadiness: "SCHICHTBEREITSCHAFT",
        licences: "FÜHRERSCHEINE",
        languages: "SPRACHEN",
        contact: "Ansprechpartner",
        skills: "KERNKOMPETENZEN",
        softSkills: "SOFT SKILLS",
        education: "AUSBILDUNG",
        cv: "WERDEGANG",
      },
      background: "#e3e6de",
      fontLight: "#fff",
      colorPrimary: "#244578",
      colorSecondary: "#eb8c2f",
    },
  },

  customerList: [] as CustomerList[],
  employeeList: [] as EmployeeList[],
  dashboardData: {} as ZvooveDashboardData,

  customerStates: getCustomerStates(CustomerStates),
  employeeStates: getEmployeeStates(EmployeeStates),
  demandStates: getDemandStates(DemandStates),

  linkings: [] as Linking[],

  globalReminders: [] as { sourceId: string; reminders: any[] }[],

  lastUpdateTimeline: "",

  reminderDSGVO: {
    eMail: {
      lastReminder: "",
      reminderText:
        "Anrede und Signatur wurden anonymisiert! Der restliche Text wird mit ChatGPT verarbeitet. Hast Du darauf geachtet das nur DSGVO-konforme Daten enthalten sind?",
      intervalHours: 8,
      intervalDays: 0,
    },
    whatsApp: {
      lastReminder: "",
      reminderText:
        "Die Signatur wurde anonymisiert! Die Anrede und der Text wird mit ChatGPT verarbeitet. Hast Du darauf geachtet das nur DSGVO-konforme Daten enthalten sind?",
      intervalHours: 8,
      intervalDays: 0,
    },
    translation: {
      lastReminder: "",
      reminderText:
        "Die Signatur wurde anonymisiert! Die Anrede und der Text wird mit ChatGPT übersetzt. Hast Du darauf geachtet das nur DSGVO-konforme Daten enthalten sind?",
      intervalHours: 8,
      intervalDays: 0,
    },
  },

  headerSpinner: {
    size: 0,
    gif: "",
  },

  jobPosterState: {
    // Stay in store.js important für jobPoster (vue an service)
    status: JobposterState.offline,
    jobAdPublishHomepage: false,
    jobAdPublishOnBfA: false,
    postingIsPaused: false,
    postingIsCancelled: false,
    totalJobAds: 0,
    postedJobAds: 0,
  },

  isDraggingItem: {
    demand: {},
    customer: {},
    candidate: {},
    employee: {},
  },

  isActiveCandidateMatching: false,

  interComponentMessage: {
    message: "",
    payload: {},
  },

  postcodesCandidates: [] as string[],
  postcodesCustomers: [] as string[],

  reminderActions: {
    interviewFailure: candidateNotShownUp,
    interviewSuccess: interviewCompleted,
    applicationRejection: applicationRejection,
    interviewExternalFailure: candidateRejectedByCustomer,
    trailWorkExternalFailure: candidateRejectedByCustomer,
  },
  candidateToMandantEvents: [
    {
      candidateStatus: LinkingStatus.interview,
      dateSelectable: DateSelectable.single,
      eventName: "Vorstellungstermin in Geschäftsstelle", //MAYBE: company admin can set
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.interview,
      dateSelectable: DateSelectable.single,
      eventName: "Vorstellungstermin Videocall",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.interviewCanceled,
      eventName: "Vorstellungstermin abgesagt",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.interviewNotShownUp,
      eventName: candidateNotShownUp,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.interviewCompleted,
      eventName: interviewCompleted,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.personnelPlacement,
      eventName: "Vermittlungskandidat",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.pool,
      eventName: "Bewerberpool",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.contractDate,
      eventName: "Vertragstermin",
      dateSelectable: DateSelectable.single,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.hired,
      eventName: "Einstellung",
      dateSelectable: DateSelectable.single,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.rejectedCandidate,
      eventName: "Absage Kandidat",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.rejectedUnattractive,
      eventName: "Absage Kandidat Drittstatt",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.rejected,
      eventName: applicationRejection,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
  ],

  candidateToCustomerEvents: [
    {
      eventName: "Profil generieren und verschicken", //MAYBE: company admin can set
      eventType: EventType.action,
    },
    {
      candidateStatus: LinkingStatus.noneProfile,
      eventName: "Absage Kandidat",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.noneProfile,
      eventName: candidateRejectedByCustomer,
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
    {
      candidateStatus: LinkingStatus.openProfile,
      eventName: candidateSuggestionDescription,
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.openProfile,
      eventName: "Profil weiter offen, Snooze",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.interviewSuggestionExternal,
      dateSelectable: DateSelectable.multiple,
      eventName: "Vorschlag Vorstellungstermin",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.interviewExternal,
      dateSelectable: DateSelectable.single,
      eventName: "Vorstellungstermin",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.trailWorkSuggestionExternal,
      dateSelectable: DateSelectable.multiple,
      eventName: "Vorschlag Probearbeiten",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.trailWorkExternal,
      dateSelectable: DateSelectable.single,
      eventName: "Probearbeiten",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.jobofferExternal,
      eventName: "Zusage",
      eventType: EventType.state,
      showOn: [Role.candidate, Role.customer],
    },
    {
      candidateStatus: LinkingStatus.jobofferAcceptedExternal,
      dateSelectable: DateSelectable.single,
      eventName: "Startet",
      eventType: EventType.state,
      showOn: [Role.candidate],
    },
  ],
  demandToCustomerEvents: [
    {
      candidateStatus: LinkingStatus.demand,
      eventName: "Anfrage",
      eventType: EventType.state,
      showOn: [Role.demand],
    },
  ],
  employeeToCustomerEvents: [
    {
      candidateStatus: LinkingStatus.tempPlacement,
      eventName: "Einsatz",
      eventType: EventType.state,
      showOn: [Role.employee, Role.customer],
    },
  ],
  followUpEvents: [
    {
      candidateStatus: LinkingStatus.followUpPrio,
      eventName: "❗Wiedervorlage",
      dateSelectable: DateSelectable.single,
      eventType: EventType.state,
      showOn: [Role.employee, Role.customer, Role.candidate, Role.demand],
    },
    {
      candidateStatus: LinkingStatus.followUp,
      eventName: "Wiedervorlage",
      dateSelectable: DateSelectable.single,
      eventType: EventType.state,
      showOn: [Role.employee, Role.customer, Role.candidate, Role.demand],
    },
  ],
};
// Plugin to save state to sessionStorage
export const vuexSessionStorage = (store: any) => {
  store.subscribe((mutation: any, state: any) => {
    sessionStorage.setItem("vuexState", JSON.stringify(state));
  });
};

// Function to load state from sessionStorage
export const loadState = () => {
  const state = sessionStorage.getItem("vuexState") as any;
  return state ? JSON.parse(state) : initialState;
};

// Function to clear state from sessionStorage
export const clearState = () => {
  sessionStorage.removeItem("vuexState");
};

export default createStore({
  state: loadState(),
  mutations: {
    ADD_LINKINGS(state, allLinkings) {
      state.linkings = allLinkings;
    },
    ADD_POSTCODE_CANDIDATES(state, postcode: string) {
      if (!state.postcodesCandidates.includes(postcode)) {
        state.postcodesCandidates.push(postcode);
      }
    },
    ADD_ZVOOVE_CUSTOMER_REDUCED(
      state: { customerList: CustomerList[] },
      customerReduced: CustomerList
    ) {
      if (Array.isArray(state.customerList)) {
        state.customerList.push(customerReduced);
      } else {
        console.error("customerList is not an array", state.customerList);
      }
    },
    CLEAR_DRAGGING_ITEM(state) {
      state.isDraggingItem = {
        demand: {},
        customer: {},
        candidate: {},
        employee: {},
      };
    },
    CLEAR_HEADER_SPINNER_ELEMENT(state) {
      state.headerSpinner.size = 0;
      state.headerSpinner.gif = "";
    },
    CLEAR_INTER_COMPONENT_MESSAGE: (state) => {
      state.interComponentMessage.message = "";
      state.interComponentMessage.payload = {};
    },
    CLEAR_POSTCODES_CANDIDATES(state) {
      state.postcodesCandidates = [];
    },
    DELETE_LINKING(state, linkingId) {
      state.linkings = state.linkings.filter(
        (link: { _id: any }) => link._id !== linkingId
      );
    },
    DELETE_WORKTIME_PROMPT(state, workTimePromptToDelete: WorkTimePrompt) {
      if (workTimePromptToDelete._id) {
        state.company.aiData.workTimePrompts =
          state.company.aiData.workTimePrompts.filter(
            (workTimePrompt: WorkTimePrompt) =>
              workTimePrompt._id !== workTimePromptToDelete._id
          );
      }
    },
    EDIT_WORKTIME_PROMPT(state, updatedWorkTimePrompt: WorkTimePrompt) {
      if (updatedWorkTimePrompt._id) {
        const index = state.company.aiData.workTimePrompts.findIndex(
          (workTimePrompt: WorkTimePrompt) =>
            workTimePrompt._id === updatedWorkTimePrompt._id
        );

        if (index !== -1) {
          state.company.aiData.workTimePrompts.splice(
            index,
            1,
            updatedWorkTimePrompt
          );
        }
      }
    },
    POSTED_JOB_ADS(state, count: number) {
      state.jobPosterState.postedJobAds = count;
    },
    RESET_JOBPOSTER(state) {
      state.jobPosterState = {
        status: JobposterState.offline,
        jobAdPublishHomepage: false,
        jobAdPublishOnBfA: false,
        postingIsPaused: false,
        postingIsCancelled: false,
        totalJobAds: 0,
        postedJobAds: 0,
      };
    },
    RESET_POSTED_JOB_ADS(state) {
      state.jobPosterState.postedJobAds = 0;
    },
    RESET_STORE(state) {
      Object.assign(state, initialState);
      clearState();
    },
    SET_CONFIG(state, config) {
      state.env = config;
    },
    SET_COMPANY_CONFIG(state, companyConfig) {
      recursiveMerge(state.company, companyConfig).then(() => {
        this.commit("UPDATE_PARTIAL_COMPANY_CONFIG", {
          key: "statusOptionsRecruit",
          value: companyConfig.statusOptionsRecruit,
        });
        this.commit("UPDATE_PARTIAL_COMPANY_CONFIG", {
          key: "salaryAdjustments",
          value: companyConfig.salaryAdjustments,
        });
        this.commit("UPDATE_PARTIAL_COMPANY_CONFIG", {
          key: "reminderSettings",
          value: companyConfig.reminderSettings,
        });
      });
    }, //for API Init
    SET_DRAGGING_ITEM(state, { type, data }) {
      state.isDraggingItem = { ...state.isDraggingItem, [type]: data };
    }, // Send payload while Dragging/ write in demand / customer / candidate / employee
    SET_HEADER_SPINNER_ELEMENT(state, { size, gif }) {
      state.headerSpinner.size = size;
      state.headerSpinner.gif = gif;
    },
    SET_INTER_COMPONENT_MESSAGE: (state, { message, payload }) => {
      state.interComponentMessage.message = message;
      state.interComponentMessage.payload = payload;
    },
    SET_JOB_AD_PUBLISH_HOMEPAGE(state, newValue) {
      state.jobPosterState.jobAdPublishHomepage = newValue;
    },
    SET_JOB_AD_PUBLISH_ON_BFA(state, newValue) {
      state.jobPosterState.jobAdPublishOnBfA = newValue;
    },
    SET_LAST_UPDATE_TIMELINE(state, lastUpdateTimeline: string) {
      alert("SET_LAST_UPDATE_TIMELINE" + lastUpdateTimeline);
      state.company.lastUpdateTimeline = lastUpdateTimeline;
    },
    SET_MANDANTS(state, mandants) {
      state.company.mandants = mandants;
    },
    SET_POSTCODE_CUSTOMERS(state, postcodes: string[]) {
      state.postcodesCustomers = postcodes;
    },
    SET_STATUS(state, payload: JobposterState) {
      if (Object.keys(JobposterState).includes(payload)) {
        state.jobPosterState.status = payload;
      } else {
        console.error("Ungültiger Status: " + payload);
      }
    },
    SET_STORE_HAS_TO_INIT(state, value) {
      state.storeHasToInit = value;
    }, //for API Init
    SET_TOTAL_JOB_ADS(state, payload) {
      state.jobPosterState.totalJobAds = payload;
      state.jobPosterState.postedJobAds = 0;
    },
    SET_USER(state, user) {
      state.company.loggedInUser = user;
    },
    SET_USER_CONFIG(state, userConfig) {
      state.company.loggedInUser.config = userConfig;
    },
    SET_USER_PROFILE(state, userProfileData) {
      state.company.loggedInUser = {
        ...state.company.loggedInUser,
        ...userProfileData,
      };
    },
    SET_WORKTIME_PROMPTS(state, newWorkTimePrompts: WorkTimePrompt[]) {
      state.company.aiData.workTimePrompts = newWorkTimePrompts;
    },
    SET_ZVOOVE_CUSTOMER_LIST(state, customerList) {
      state.customerList = customerList;
    },
    SET_ZVOOVE_DASHBOARD_DATA(state, dashboardData) {
      state.dashboardData = dashboardData;
    },
    SET_ZVOOVE_EMPLOYEE_LIST(state, employeeList) {
      state.employeeList = employeeList;
    },
    TOGGLE_POSTING_IS_CANCELLED(state) {
      state.jobPosterState.postingIsCancelled =
        !state.jobPosterState.postingIsCancelled;
    },
    TOGGLE_POSTING_IS_PAUSED(state) {
      state.jobPosterState.postingIsPaused =
        !state.jobPosterState.postingIsPaused;
    },
    UPDATE_COMPANY_CONFIG(state, payload) {
      Object.assign(state.company, payload);
    },
    UPDATE_PARTIAL_COMPANY_CONFIG(state, { key, value }) {
      if (value && Array.isArray(value) && value.length > 0) {
        state.company[key] = [...value];
      }
    },
    UPDATE_DISPATCHER_BOARD_FILTER(
      state: any,
      payload: {
        columnName: keyof typeof state.company.loggedInUser.config.dispatcherBoard;
        property: keyof (typeof state.company.loggedInUser.config.dispatcherBoard)["columnDemand"];
        value: string[];
      }
    ) {
      const column =
        state.company.loggedInUser.config.dispatcherBoard[payload.columnName];
      if (
        column &&
        Object.prototype.hasOwnProperty.call(column, payload.property)
      ) {
        (column as any)[payload.property] = payload.value;
      }
    },
    UPDATE_LAST_AUTO_UPDATE_FROM_ERP(state, currentTime) {
      state.company.softwareIntegration.lastAutoUpdateFromErp = currentTime;
    },
    UPDATE_LOGGEDIN_MANDANTS(state, newMandantUuids) {
      state.company.loggedInUser.config.loggedInMandants = newMandantUuids;
    },
  },
  actions: {
    async loadConfig({ commit }) {
      try {
        const response = await fetch("/config.json");
        const config = await response.json();
        commit("SET_CONFIG", config);
      } catch (error) {
        console.error("Error loading config:", error);
      }
    },
    async addCustomerReducedToCustomerList(
      { commit },
      customerReduced: CustomerList
    ) {
      try {
        const customerService = CustomerService.getInstance();
        await customerService.addCustomerReducedToList(customerReduced);

        commit("ADD_ZVOOVE_CUSTOMER_REDUCED", customerReduced);
      } catch (error) {
        console.error("Error adding customer reduced:", error);
      }
    },
    async loadLinkingsAndAddMissingDescriptions({ getters }) {
      const candidateService = new CandidateService();
      const customerService = new CustomerService();
      const mandants = getters.allSelectedMandants;
      const linkingService = new LinkingService();

      try {
        const matchingLinkings = await linkingService.getAllLinkings(mandants);

        for (const linking of matchingLinkings) {
          let isLinkingUpdated = false;

          if (!linking.description) {
            linking.description = {} as LinkingDescription;
          }

          if (linking.linkingCore.candidate && !linking.description.firstName) {
            try {
              const candidateDetails = await candidateService.getCandidateById(
                linking.linkingCore.candidate
              );
              if (candidateDetails) {
                Object.assign(linking.description, {
                  firstName: candidateDetails.firstName,
                  lastName: candidateDetails.lastName,
                  postCode: candidateDetails.addressPostalCode,
                  city: candidateDetails.addressCity,
                  uuid: candidateDetails.uuid,
                });
                isLinkingUpdated = true;
              }
            } catch (error) {
              console.error("Error fetching candidatedetails: ", error);
            }
          }

          if (
            linking.linkingCore.customer &&
            !linking.description.customerName
          ) {
            try {
              const customerDetails = await customerService.getById(
                linking.linkingCore.customer
              );
              if (customerDetails && customerDetails.generalData) {
                Object.assign(linking.description, {
                  customerName: customerDetails.generalData.name,
                  customerPostCode:
                    customerDetails.addressAndCommunication.postalCode,
                  customerCity: customerDetails.addressAndCommunication.city,
                });
                isLinkingUpdated = true;
              } else {
                console.error(
                  "customerDetails oder customerDetails.generalData ist undefined"
                );
              }
            } catch (error) {
              console.error("Error fetching customerdetails: ", error);
            }
          }

          if (isLinkingUpdated) {
            try {
              await linkingService.updateLinking(linking);
            } catch (error) {
              console.error(
                `Error updating linking with ID ${linking._id}: `,
                error
              );
            }
          }
        }

        this.state.linkings = matchingLinkings;
      } catch (error) {
        console.error("Error loading linking: ", error);
      }
    },
    async loadStoreInitialData({ commit }) {
      clearState();
      try {
        const companyConfigService = CompanyConfigService.getInstance();
        const mandantService = new MandantService();
        const userService = new UserService();
        const companyConfig = await companyConfigService.getConfig();
        const mandantsResult = await mandantService.getMandants();
        const userResult = await userService.getUser();

        if (companyConfig) {
          candidateSuggestionDescription = companyConfig.timelineEntryTypes
            ? companyConfig.timelineEntryTypes.candidateSuggestion.description
            : ""; //important to set the same string for this state
          commit("SET_COMPANY_CONFIG", companyConfig);
        }
        if (mandantsResult) {
          commit("SET_MANDANTS", mandantsResult);
        }
        commit("SET_STORE_HAS_TO_INIT", false); //That components know the store has the backend-data
        if (userResult) {
          commit("SET_USER", userResult);
        }
      } catch (error) {
        console.error("Error loading initial data: ", error);
      }
    },
    async loadCustomerAndEmployeeBackgroundData({ commit }) {
      try {
        const customerService = CustomerService.getInstance();
        const employeeService = new EmployeeService();
        const customerList = await customerService.getList();
        const employeeList = await employeeService.getList();
        if (customerList) {
          commit("SET_ZVOOVE_CUSTOMER_LIST", customerList);
        }
        if (employeeList) {
          commit("SET_ZVOOVE_EMPLOYEE_LIST", employeeList);
        }
      } catch (error) {
        console.error("Error loading initial data: ", error);
      }
    },
    resetStore({ commit }) {
      clearState();
      commit("RESET_STORE");
    },
    updateDispatcherBoardFilter(
      { commit }: { commit: any },
      payload: {
        columnName: string;
        property: string;
        value: string[];
        validArray?: string[];
      }
    ): Promise<void> {
      return new Promise<void>((resolve) => {
        let filteredValues = payload.value;
        if (payload.validArray && Array.isArray(payload.validArray)) {
          filteredValues = payload.value.filter((item) =>
            payload.validArray?.includes(item)
          );
        }
        const uniqueValues = Array.from(new Set(filteredValues));
        const newPayload = {
          ...payload,
          value: uniqueValues,
        };

        commit("UPDATE_DISPATCHER_BOARD_FILTER", newPayload);
        resolve();
      });
    },

    updateLastUpdateTimeline({ commit }, lastUpdateTimeline: string) {
      commit("SET_LAST_UPDATE_TIMELINE", lastUpdateTimeline);
    },
  },
  getters: {
    allSelectedMandants(state) {
      const { columnDemand, columnCustomer, columnCandidate } =
        state.company.loggedInUser.config.dispatcherBoard;
      const { loggedInMandants } = state.company.loggedInUser.config;

      const allMandants = [
        ...(columnDemand?.filterMandants || []),
        ...(columnCustomer?.filterMandants || []),
        ...(columnCandidate?.filterMandants || []),
        ...(loggedInMandants || []),
      ];

      const uniqueMandants = [...new Set(allMandants)];

      return uniqueMandants;
    },
    allZvooveOneGeschaeftsstelleIds: (state) => {
      const ids = state.company.mandants.map((mandant: Mandant) =>
        parseInt(mandant.branchNumber || "0")
      );
      return [...new Set(ids)].sort((a: any, b: any) => a - b);
    },
    candidateToCustomerEvents: (state) => state.candidateToCustomerEvents,
    candidateToMandantEvents: (state) => state.candidateToMandantEvents,
    demandToCustomerEvents: (state) => state.demandToCustomerEvents,
    employeeToCustomerEvents: (state) => state.employeeToCustomerEvents,
    company: (state) => state.company,
    customerStates: (state) => state.customerStates,
    customerList: (state) => state.customerList,
    employeeStates: (state) => state.employeeStates,
    employeeList: (state) => state.employeeList,
    followUpEvents: (state) => state.followUpEvents,
    funnelMandants: (state) =>
      state.company.mandants.map((mandant: Mandant) => ({
        uuid: mandant.uuid,
        name: mandant.zvoovename,
        plzs: mandant.postalcodes,
      })),
    demandStates: (state) => state.demandStates,
    getAllMandantL1: (state) => {
      const mandantL1s = state.company.mandants
        .map((mandant: Mandant) => mandant.mandantL1)
        .filter(
          (mandantL1: string | null) => mandantL1 != null && mandantL1 !== ""
        );
      return Array.from(new Set(mandantL1s));
    },
    getLinkings: (state) => state.linkings,
    getLinkingsBySearchString: (state) => (searchString: string) => {
      return state.linkings.filter((linking: any) => {
        return Object.values(linking.linkingCore).some(
          (value) => value === searchString
        );
      });
    },
    getCustomerNameByNumberFromCustomerList:
      (state) => (customerNumber: string) => {
        const customer = state.customerList.find(
          (cust: any) => cust.customerNumber === customerNumber
        );
        return customer ? customer.name : null;
      },
    getCustomerNumberByNameFromCustomerList:
      (state) => (customerName: string) => {
        const customer = state.customerList.find(
          (cust: any) => cust.name === customerName
        );
        return customer ? customer.customerNumber : null;
      },
    getEnv: (state) => state.env,
    getEmployeeNameByNumberFromEmployeeList:
      (state) => (employeeNumber: string) => {
        const employee = state.employeeList.find(
          (emp: any) => emp.employeeNumber === employeeNumber
        );
        if (!employee) {
          return null;
        }

        const nameParts = employee.name.split(" ");
        const firstName = nameParts[0];
        const lastName = nameParts[nameParts.length - 1];
        return {
          firstName,
          lastName,
        };
      },
    getEventNameByCandidateStatus: (state) => (status: string) => {
      const allEvents = [
        ...state.candidateToCustomerEvents,
        ...state.candidateToMandantEvents,
      ];
      const event = allEvents.find((event) => event.candidateStatus === status);
      return event ? event.eventName : null;
    },
    getLinkingstatusFromEventName: (state) => (eventType: string) => {
      const allEvents = [
        ...state.candidateToCustomerEvents,
        ...state.candidateToMandantEvents,
        ...state.followUpEvents,
      ];

      const event = allEvents.find((event) => event.eventName === eventType);
      return event ? event.candidateStatus : null;
    },
    getExpiredLinkingReminders: (state, getters) => {
      const expiredReminders = [] as ExpiredReminder[];

      if (
        !state.company.reminderSettings ||
        state.company.reminderSettings.length === 0
      ) {
        return expiredReminders;
      }

      return state.linkings.reduce(
        (acc: ExpiredReminder[], linking: Linking) => {
          const latestEvent = linking.events[linking.events.length - 1];

          if (latestEvent && latestEvent.eventDate.length > 0) {
            const eventDate = moment(
              latestEvent.eventDate[0],
              "YYYY-MM-DD HH:mm"
            );
            const translatedEventType = getters.getLinkingstatusFromEventName(
              latestEvent.eventType
            );

            if (translatedEventType) {
              const reminderConfig = state.company.reminderSettings.find(
                (reminder: { reminderFor: string }) =>
                  reminder.reminderFor === translatedEventType
              );

              if (reminderConfig) {
                const expirationDate = eventDate
                  .clone()
                  .add(reminderConfig.daysAfter, "days")
                  .add(reminderConfig.hoursAfter, "hours");

                if (expirationDate.isBefore(moment())) {
                  acc.push({
                    event: latestEvent,
                    description: linking.description,
                    expiredSince: expirationDate.from(moment()),
                    needToContact: reminderConfig.needToContact,
                    showPrompt: reminderConfig.showPrompt,
                    buttonResult: reminderConfig.buttonResult,
                    buttonFailure: reminderConfig.buttonFailure,
                    buttonSuccess: reminderConfig.buttonSuccess,
                    message: reminderConfig.message,
                    reminderFor: reminderConfig.reminderFor,
                  });
                }
              }
            }
          }

          return acc;
        },
        []
      );
    },
    getExpiredReminderForLinkingCore:
      (state, getters) => (linkingCore: Partial<LinkingCore>) => {
        const linking = state.linkings.find((link: Linking) => {
          return (
            (!linkingCore.mandant ||
              link.linkingCore.mandant === linkingCore.mandant) &&
            (!linkingCore.candidate ||
              link.linkingCore.candidate === linkingCore.candidate) &&
            (!linkingCore.customer ||
              link.linkingCore.customer === linkingCore.customer) &&
            (!linkingCore.demand ||
              link.linkingCore.demand === linkingCore.demand) &&
            (!linkingCore.employee ||
              link.linkingCore.employee === linkingCore.employee) &&
            (!linkingCore.user || link.linkingCore.user === linkingCore.user)
          );
        });

        if (!linking || linking.events.length === 0) {
          return null;
        }

        const latestEvent = linking.events[linking.events.length - 1];

        if (!latestEvent || latestEvent.eventDate.length === 0) {
          return null;
        }

        const eventDate = moment(latestEvent.eventDate[0], "YYYY-MM-DD HH:mm");
        const translatedEventType = getters.getLinkingstatusFromEventName(
          latestEvent.eventType
        );

        if (!translatedEventType) {
          return null;
        }

        const reminderConfig = state.company.reminderSettings.find(
          (reminder: { reminderFor: string }) =>
            reminder.reminderFor === translatedEventType
        );

        if (!reminderConfig) {
          return null;
        }

        const expirationDate = eventDate
          .clone()
          .add(reminderConfig.daysAfter, "days")
          .add(reminderConfig.hoursAfter, "hours");

        if (expirationDate.isBefore(moment())) {
          return {
            event: latestEvent,
            description: linking.description,
            expiredSince: expirationDate.from(moment()),
            needToContact: reminderConfig.needToContact,
            showPrompt: reminderConfig.showPrompt,
            buttonResult: reminderConfig.buttonResult,
            buttonFailure: reminderConfig.buttonFailure,
            buttonSuccess: reminderConfig.buttonSuccess,
            message: reminderConfig.message,
            reminderFor: reminderConfig.reminderFor,
          };
        }

        return null;
      },

    getBranchNumberByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.branchNumber : null;
    },
    getInitialsByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.branchInitials : null;
    },
    getLoggedInMandantBranchNumbers: (state, getters) => {
      const loggedInUser = state.company.loggedInUser;
      const config = loggedInUser.config;
      const loggedInMandants = config && config.loggedInMandants;

      if (!loggedInMandants) return [];

      return state.company.loggedInUser.config.loggedInMandants
        .map((uuid: string) => {
          const id = parseInt(getters.getBranchNumberByUuid(uuid), 10);
          return isNaN(id) ? null : id;
        })
        .filter((id: string) => id !== null);
    },
    getLoggedInMandantL1Numbers: (state, getters) => {
      const loggedInUser = state.company.loggedInUser;
      const config = loggedInUser.config;
      const loggedInMandants = config && config.loggedInMandants;

      if (!loggedInMandants) return [];

      return state.company.loggedInUser.config.loggedInMandants
        .map((uuid: string) => {
          const id = parseInt(getters.getMandantL1NumberByUuid(uuid), 10);
          return isNaN(id) ? null : id;
        })
        .filter((id: string) => id !== null);
    },
    getMandantL1NumberByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.mandantL1 : null;
    },
    getLinkingAppointmentsForCalendar: (state, getters) => {
      const loggedInMandants = getters.getLoggedInMandantUuids;

      return state.linkings
        .filter((linking: Linking) => {
          const isValidMandant = linking.mandants.some((uuid) =>
            loggedInMandants.includes(uuid)
          );
          return isValidMandant && linking.events.length > 0;
        })
        .flatMap((linking: Linking) => {
          const lastEvent = linking.events[linking.events.length - 1];

          if (
            !lastEvent ||
            !lastEvent.eventDate ||
            lastEvent.eventDate.length === 0
          ) {
            return [];
          }

          return lastEvent.eventDate.map((dateString) => {
            const startDate = moment(dateString, [
              "YYYY-MM-DDTHH:mm:ss.sssZ",
              "YYYY-MM-DD HH:mm",
            ]).toDate();
            const endDate = moment(startDate).add(1, "hour").toDate();

            const candidateName = linking.description
              ? linking.description.firstName +
                " " +
                linking.description.lastName
              : "Unbekannt";

            const customerName = linking.description?.customerName
              ? `bei ${linking.description.customerName}`
              : "";

            const mandantIds = linking.mandants
              .map((uuid) => getters.getMandantByUuid(uuid)?.branchNumber)
              .filter(Boolean)
              .join(" und ");

            return {
              start: startDate,
              end: endDate,
              title:
                `${candidateName} ${customerName} (NL${mandantIds})`.trim(),
              class: lastEvent.eventType,
            };
          });
        });
    },
    getLoggedInMandantUuids: (state) =>
      state.company.loggedInUser.config.loggedInMandants,
    getMandantByUuid: (state) => (uuid: string) => {
      return state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
    },
    getMandantByBranchNumber: (state) => (branchNumber: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.branchNumber === branchNumber
      );
      return mandant ? mandant.uuid : null;
    },
    getMandantByBranchNumberAndMandantL1:
      (state) => (branchNumber: string, mandantL1: string) => {
        const mandant = state.company.mandants.find(
          (mandant: Mandant) =>
            mandant.branchNumber === branchNumber &&
            mandant.mandantL1 === mandantL1
        );
        return mandant ? mandant.uuid : null;
      },
    getMandantBranchNumberByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.branchNumber : "Unbekannter Mandant";
    },
    getMandantL1ByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.mandantL1 : "Unbekannter Mandant";
    },
    getMandantNameByUuid: (state) => (uuid: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.uuid === uuid
      );
      return mandant ? mandant.name : "Unbekannter Mandant";
    },
    getMandantUuidByZvoovename: (state) => (zvoovename: string) => {
      const mandant = state.company.mandants.find(
        (mandant: Mandant) => mandant.zvoovename === zvoovename
      );
      return mandant ? mandant.uuid : "Unbekannte UUID";
    },
    getSalaryAdjustment: (state) => (payrollInfoType: string) => {
      const matchedSalaryAdjustment = state.company.salaryAdjustments.find(
        (salaryAdjustment: SalaryAdjustment) =>
          salaryAdjustment.payrollInfoType === payrollInfoType
      );
      return matchedSalaryAdjustment;
    },
    getStatusByValue: (state) => (value: string) => {
      const option = state.company.statusOptionsRecruit.find(
        (option: any) => option.value === value
      );
      return option ? option.status : [];
    },
    getStatusByEmployeeNumber: (state) => (employeeNumber: string) => {
      const employee = state.employeeList.find(
        (emp: EmployeeList) => emp.employeeNumber === employeeNumber
      );
      return employee ? employee.status : null;
    },
    globalReminders: (state) => state.globalReminders,
    hasLinkingForCandidate: (state) => (candidateId: string) => {
      return state.linkings.some(
        (linking: Linking) => linking.linkingCore.candidate === candidateId
      );
    },
    hasLinkingForCustomer: (state) => (customerId: string) => {
      return state.linkings.some(
        (linking: Linking) => linking.linkingCore.customer === customerId
      );
    },
    interComponentMessage: (state) => state.interComponentMessage,
    isJobAdPublishOnBfA: (state) => state.jobPosterState.jobAdPublishOnBfA,
    jobPosterState: (state) => state.jobPosterState,
    lastUpdateTimeline: (state) => state.lastUpdateTimeline,
    mandants: (state) => state.company.mandants,
    mandantUuids: (state) =>
      state.company.mandants.map((mandant: Mandant) => mandant.uuid),
    mailTemplates: (state) => state.company.aiData.template.mail,
    pdHubToken: (state) => state.company.apiKeys.pdHubToken,
    reducedMandants: (state) =>
      state.company.mandants.map((mandant: Mandant) => ({
        uuid: mandant.uuid,
        name: mandant.name,
        zvoovename: mandant.zvoovename,
        branchNumber: mandant.branchNumber,
        branchInitials: mandant.branchInitials,
      })),
    reminderActions: (state) => state.reminderActions,
    reminderSettings: (state) => state.company.reminderSettings,
    softwareIntegration: (state) => state.company.softwareIntegration,
    statusOptionsRecruit: (state) => state.company.statusOptionsRecruit,
    storeHasToInit: (state) => state.storeHasToInit,
    timelineEntryTypes: (state) => state.company.timelineEntryTypes,
    user: (state) => state.company.loggedInUser,
    workTimePrompts: (state) => state.company.aiData.workTimePrompts,
    zvooveCustomers: (state) => state.customers,
    zvooveDashboardData: (state) => state.dashboardData,
    zvooveEmployeeList: (state) => state.employeeList,
    isJobAdPublishOnHomepage: (state) =>
      state.jobPosterState.jobAdPublishHomepage,
  },

  modules: {
    // Space to add additional modules
  },
  plugins: [vuexSessionStorage],
});

export function useStore() {
  return baseUseStore(key);
}
