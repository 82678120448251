<!-- src/components/config/ai/AiConfigCompanyPrompts.vue -->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-building</v-icon>
      Prompts: Unternehmensebene
    </h2>

    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.prompt.company.weAre"
            label="Unternehmensbeschreibung"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.prompt.company.weAreShort"
            label="Wer wir sind (kurz)"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.prompt.company.whatsAppCandidate"
            label="WhatsApp an einen Kandidaten"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.prompt.company.mailCandidate"
            label="Email an einen Kandidaten"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.prompt.company.tagsCandidate"
            label="Tags erstellen für Kandidaten"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.prompt.company.tagsCompany"
            label="Tags erstellen für Kunden"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.prompt.company.mailCustomer"
            label="Email an einen Kunden"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <!-- TODO: activate when customer whatsapp feature is implemented
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.prompt.company.whatsAppCustomer"
            label="WhatsApp an einen Kunden"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        -->
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.prompt.company.mailEmployee"
            label="Email an einen Mitarbeiter"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.prompt.company.whatsAppEmployee"
            label="WhatsApp an einen Mitarbeiter"
            rows="6"
            class="mb-4"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        class="mt-10"
        type="submit"
        color="success"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { AiData } from "@/models/company-config.model";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "AiConfigCompanyPrompts",
  props: {
    modelValue: {
      type: Object as PropType<AiData>,
      required: true,
    },
  },
  data() {
    return {
      localAiData: { ...this.modelValue },
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    modelValue(newValue: AiData) {
      this.localAiData = { ...newValue };
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localAiData);
      this.$emit("save");
    },
  },
});
</script>
