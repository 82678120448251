<!-- src/components/config/ai/AiConfigCompanyPrompts.vue -->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-user</v-icon>
      Prompts: Benutzerebene
    </h2>
    <v-form v-if="user?.config?.aiData" @submit.prevent="updateUserConfig">
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="user.config.aiData.whatsAppStyle"
            label="Stil bzw. Beispiele für WhatsApp Nachrichten"
            rows="7"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="user.config.aiData.mailStyle"
            label="Stil bzw. Beispiele für Emails"
            rows="7"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <h2 class="mb-5">
            <v-icon size="small" class="mr-2">fa-solid fa-signature</v-icon>
            Signatur E-Mail
          </h2>
          <div class="main-container">
            <div
              class="editor-container editor-container_classic-editor"
              ref="editorContainerElement"
            >
              <div class="editor-container__editor">
                <div ref="editorElement">
                  <ckeditor
                    v-if="isLayoutReady"
                    v-model="user.config.aiData.mailSignature"
                    :editor="editor"
                    :config="config"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        class="mt-10"
        type="submit"
        color="success"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { AiData } from "@/models/company-config.model";
import { User } from "@/models/user.model";
import { UserService } from "@/services/api/user.service";
import ToastService from "@/services/toast.service";
import { UserHelperService } from "@/services/user-helper.service";
import { defineComponent, PropType } from "vue";
import "ckeditor5/ckeditor5.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import translations from "ckeditor5/translations/de.js"; // FIXME

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  Paragraph,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TodoList,
  Underline,
  Undo,
} from "ckeditor5";

export default defineComponent({
  name: "AiConfigUserPrompts",
  props: {
    modelValue: {
      type: Object as PropType<AiData>,
      required: true,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      config: null as any, // CKEditor needs the DOM tree before calculating the configuration.
      localAiData: { ...this.modelValue },
      isLayoutReady: false,
      user: {} as User,
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    modelValue(newValue: AiData) {
      this.localAiData = { ...newValue };
    },
  },
  created() {
    this.getUserConfig();
  },
  mounted() {
    this.config = {
      toolbar: {
        items: [
          "undo",
          "redo",
          "|",
          "selectAll",
          "|",
          "heading",
          "|",
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "subscript",
          "superscript",
          "|",
          "specialCharacters",
          "horizontalLine",
          "link",
          "insertImage",
          "insertTable",
          "highlight",
          "blockQuote",
          "|",
          "alignment",
          "|",
          "bulletedList",
          "numberedList",
          "todoList",
          "indent",
          "outdent",
          "|",
          "accessibilityHelp",
        ],
        shouldNotGroupWhenFull: true,
      },
      plugins: [
        AccessibilityHelp,
        Alignment,
        AutoImage,
        AutoLink,
        Autosave,
        BalloonToolbar,
        Base64UploadAdapter,
        BlockQuote,
        Bold,
        Essentials,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        Heading,
        Highlight,
        HorizontalLine,
        ImageBlock,
        ImageCaption,
        ImageInline,
        ImageInsert,
        ImageInsertViaUrl,
        ImageResize,
        ImageStyle,
        ImageTextAlternative,
        ImageToolbar,
        ImageUpload,
        Indent,
        IndentBlock,
        Italic,
        Link,
        LinkImage,
        List,
        ListProperties,
        Paragraph,
        SelectAll,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        TodoList,
        Underline,
        Undo,
      ],
      balloonToolbar: [
        "bold",
        "italic",
        "|",
        "link",
        "insertImage",
        "|",
        "bulletedList",
        "numberedList",
      ],
      fontFamily: {
        supportAllValues: true,
      },
      fontSize: {
        options: [10, 12, 14, "default", 18, 20, 22],
        supportAllValues: true,
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: "h1",
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: "h2",
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
          {
            model: "heading3",
            view: "h3",
            title: "Heading 3",
            class: "ck-heading_heading3",
          },
          {
            model: "heading4",
            view: "h4",
            title: "Heading 4",
            class: "ck-heading_heading4",
          },
          {
            model: "heading5",
            view: "h5",
            title: "Heading 5",
            class: "ck-heading_heading5",
          },
          {
            model: "heading6",
            view: "h6",
            title: "Heading 6",
            class: "ck-heading_heading6",
          },
        ],
      },
      image: {
        toolbar: [
          "toggleImageCaption",
          "imageTextAlternative",
          "|",
          "imageStyle:inline",
          "imageStyle:wrapText",
          "imageStyle:breakText",
          "|",
          "resizeImage",
        ],
      },
      initialData: "",
      language: "de",
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: "https://",
        decorators: {
          toggleDownloadable: {
            mode: "manual",
            label: "Downloadable",
            attributes: {
              download: "file",
            },
          },
        },
      },
      list: {
        properties: {
          styles: true,
          startIndex: true,
          reversed: true,
        },
      },
      placeholder: "Type or paste your content here!",
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableProperties",
          "tableCellProperties",
        ],
      },
      translations: [translations],
    };

    this.isLayoutReady = true;
  },
  methods: {
    getUserConfig() {
      const user = this.$store.getters.user;
      if (user && user.config) {
        if (!user.config.aiData) {
          user.config.aiData = {
            mailStyle: "",
            whatsAppStyle: "",
          };
        }
      }
      this.user = user as User;
    },
    updateUserConfig() {
      this.userService.updateUserConfig(this.user.config).then(async () => {
        ToastService.showSuccess("Gespeichert");
      });
    },
    handleSubmit() {
      this.$emit("update:modelValue", this.localAiData);
      this.$emit("save");
    },
  },
});
</script>
