// src/services/timeline.service.ts
import { Customer, Participant, Timeline } from "@/models/timeline.model";
import { CustomerService } from "@/services/api/customer.service";
import store from "../store/store";
import { TimelineService } from "./api/timeline.service";
import { EmployeeService } from "./api/employee.service";
import { AllAtsAdapter } from "../adapter/all-ats.adapter";
import { AllErpAdapter } from "../adapter/all-erp.adapter";

interface TimeLineObject {
  note: string;
  entryType?: string;
  mandant?: string;
  customer?: Partial<Customer>;
  participant?: Partial<Participant>;
}

// we need that service to add every poop in the timeline
export class TimelineHelperService {
  private timelineService = new TimelineService();

  async timelineAutoDocu({
    note,
    entryType = "SYSTEM",
    mandant = "",
    customer = { name: "", contact: "", customerId: "" },
    participant = { name: "", uuid: "", employeeId: "" },
  }: TimeLineObject): Promise<void> {
    const newEntryTimeline: Timeline = {
      user: "",
      userId: "",
      mandant: mandant,
      entryDate: "",
      entryType,
      customer: {
        name: customer.name ?? "",
        contact: customer.contact ?? "",
        customerId: customer.customerId ?? "",
      },
      participant: {
        name: participant.name ?? "",
        uuid: participant.uuid ?? "",
        employeeId: participant.employeeId ?? "",
      },
      note,
    };

    try {
      await this.timelineService.postTimeline(newEntryTimeline);
    } catch (error) {
      console.error("Fehler beim Speichern des Timeline-Eintrags:", error);
    }

    //AutoDocu zvoove One: Company
    if (customer.customerId) {
      const customerService = new CustomerService();
      const response = await customerService.getById(customer.customerId);
      const customerNumber = response.customerNumber;
      await AllErpAdapter.createCustomerTimelineEntry(
        customerNumber,
        entryType,
        note,
        mandant
      );
    }
    //employee
    if (participant.employeeId) {
      const employeeService = new EmployeeService();
      const employee = await employeeService.getEmployee(
        participant.employeeId
      );
      const employeeNumber = employee.employeeNumber;
      await AllErpAdapter.createEmployeeTimelineEntry(
        employeeNumber,
        entryType,
        note,
        mandant
      );
    }

    //candidate
    if (
      participant.uuid &&
      store.state.company.softwareIntegration.atsAutoDocu
    ) {
      await AllAtsAdapter.addTimelineEntry(participant.uuid, entryType, note);
    }
  }
}
