import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-25d8ca96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = {
  class: "editor-container editor-container_classic-editor editor-container_include-style",
  ref: "editorContainerElement"
}
const _hoisted_3 = { class: "editor-container__editor" }
const _hoisted_4 = { ref: "editorElement" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ckeditor = _resolveComponent("ckeditor")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.isLayoutReady)
              ? (_openBlock(), _createBlock(_component_ckeditor, {
                  key: 0,
                  modelValue: _ctx.config.initialData,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.config.initialData) = $event)),
                  editor: _ctx.editor,
                  config: _ctx.config,
                  onReady: _ctx.onReady
                }, null, 8, ["modelValue", "editor", "config", "onReady"]))
              : _createCommentVNode("", true)
          ], 512)
        ])
      ], 512)
    ])
  ]))
}