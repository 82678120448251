import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainDashboard = _resolveComponent("MainDashboard")!
  const _component_LeftHeader = _resolveComponent("LeftHeader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_PdMasterHeader = _resolveComponent("PdMasterHeader")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.layout === _ctx.AppLayout.dispositioner)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MainHeader),
          _createVNode(_component_MainDashboard)
        ]))
      : _createCommentVNode("", true),
    (_ctx.layout === _ctx.AppLayout.pdmaster)
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 1,
          class: "d-flex"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_LeftHeader)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "11"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PdMasterHeader),
                _createVNode(_component_MainDashboard)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}