<!-- src/components/config/software-integration/ConfigIndexAnzeigendaten.vue -->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-rectangle-ad</v-icon>INDEX
      Anzeigendaten
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="6">
          <v-slider
            v-model="localConfig.softwareIntegration.indexJobAdRefreshInterval"
            :max="30"
            :min="1"
            step="1"
            label="aktualisieren bei Firmen (Tage)"
            thumb-label
          ></v-slider>
        </v-col>
        <v-col cols="12" md="6">
          <v-slider
            v-model="localConfig.softwareIntegration.indexJobAdAgeLimitDays"
            :max="365"
            :min="1"
            step="1"
            label="max. Alter (Firmen)"
            thumb-label
          ></v-slider>
        </v-col>
        <v-col cols="12" md="6">
          <v-slider
            v-model="localConfig.softwareIntegration.indexJobLeadsAgeLimitDays"
            :max="365"
            :min="1"
            step="1"
            label="max. Alter (Kandidat)"
            thumb-label
          ></v-slider>
        </v-col>
        <v-col cols="12" md="6">
          <v-slider
            v-model="localConfig.softwareIntegration.indexJobLeadsMaxAds"
            :max="250"
            :min="1"
            step="1"
            label="max. Anzahl Stellenanzeigen"
            thumb-label
          ></v-slider>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.baseUrlIndexCustomers"
            label="Link zur Schnittstelle für Kunden"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.baseURLIndexAds"
            label="Link zur Schnittstelle für Stellenanzeigen"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.baseURLIndexAuth"
            label="Base URL Index Ads"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            color="primary"
            class="ma-2"
            @click="openIndexAuthModal"
          >
            <v-icon
              :class="
                localConfig.apiKeys.indexAnzeigendaten.length > 0
                  ? 'fa-solid fa-lock-open'
                  : 'fa-solid fa-lock'
              "
            ></v-icon>
            &nbsp;&nbsp;neues Token einrichten
          </v-btn>
        </v-col>
        <template
          v-for="(ad, index) in localConfig.apiKeys.indexAnzeigendaten"
          :key="ad._id"
        >
          <v-col cols="12" md="6">
            <v-card
              :variant="vStyle.card.variant || undefined"
              :rounded="vStyle.card.rounded || undefined"
              :border="vStyle.card.border || undefined"
              class="mb-4 py-4"
            >
              <v-row class="mt-5 pr-5">
                <v-col cols="12" md="6">
                  <v-combobox
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="
                      localConfig.apiKeys.indexAnzeigendaten[index]
                        .creditalsForUsersOnly
                    "
                    :items="creditalsForUsersOnlyItems"
                    label="creditalsForUsersOnly"
                    prepend-icon="mdi-filter-variant"
                    chips
                    clearable
                    multiple
                  >
                  </v-combobox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-combobox
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="
                      localConfig.apiKeys.indexAnzeigendaten[index]
                        .creditalsForMandantsOnly
                    "
                    :items="creditalsForMandantsOnlyItems"
                    label="creditalsForMandantsOnly"
                    prepend-icon="mdi-filter-variant"
                    chips
                    clearable
                    multiple
                  >
                  </v-combobox>
                </v-col>
              </v-row>
              <v-card-text class="pl-10">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="localConfig.apiKeys.indexAnzeigendaten[index].LOGIN"
                  :disabled="getUserRole() !== uberAdminRole"
                  density="compact"
                  label="Login"
                ></v-text-field>
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="localConfig.apiKeys.indexAnzeigendaten[index].TOKEN"
                  :disabled="getUserRole() !== uberAdminRole"
                  density="compact"
                  label="Token"
                ></v-text-field>
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="localConfig.apiKeys.indexAnzeigendaten[index].EXPIRE"
                  :disabled="getUserRole() !== uberAdminRole"
                  density="compact"
                  label="Expire"
                ></v-text-field>
                <v-col
                  cols="12"
                  md="2"
                  class="d-flex align-center justify-center"
                >
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="abort" @click="deleteAd(index)">Löschen</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </template>
      </v-row>
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        class="mt-10"
        type="submit"
        color="success"
        >Speichern</v-btn
      >
    </v-form>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Geben Sie Ihre Anzeigendaten Zugangsdaten ein</v-card-title
        >
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="credentials.login"
              label="User"
              required
            ></v-text-field>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="credentials.password"
              label="Passwort"
              type="password"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-3" @click="submitIndexAuthModal">OK</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-4" @click="dialog = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";
import { AnzeigedatenService } from "@/services/api/anzeigendaten.service";
import { UserHelperService } from "@/services/user-helper.service";
import { UserRole } from "@/enums/user-role.enum";

export default defineComponent({
  name: "ConfigIndexAnzeigendaten",
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
      dialog: false,
      credentials: {
        login: "",
        password: "",
      },
      creditalsForUsersOnlyItems: ["all"], //TODO: Get all Company Users in this Array "all" give access right 4 whole company
      creditalsForMandantsOnlyItems: ["all"], //TODO: Get all Company Mandants in this Array "all" give access right 4 whole company
      uberAdminRole: UserRole.UberAdmin,
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  methods: {
    getUserRole() {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole();
      return userRole;
    },
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
    async submitIndexAuthModal() {
      try {
        const anzeigedatenService = AnzeigedatenService.getInstance();
        const response = await anzeigedatenService.authAndGetIndexApiKey(
          this.credentials.login,
          this.credentials.password
        );
        if (response && response.length > 0) {
          const newAd = response[0];
          this.localConfig.apiKeys.indexAnzeigendaten.push({
            EXPIRE: newAd.EXPIRE,
            TOKEN: newAd.TOKEN,
            LOGIN: newAd.LOGIN,
            creditalsForUsersOnly: ["all"],
            creditalsForMandantsOnly: ["all"],
          });
          this.$emit("update:modelValue", this.localConfig);
          this.dialog = false;
          this.credentials = { login: "", password: "" }; // Reset the form
        } else {
          console.error("Invalid response:", response);
        }
      } catch (error) {
        console.error("Error while fetching API key:", error);
      }
    },
    openIndexAuthModal() {
      this.dialog = true;
    },
    deleteAd(index: number) {
      this.localConfig.apiKeys.indexAnzeigendaten.splice(index, 1);
    },
  },
});
</script>
