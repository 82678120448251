<template>
  <v-card
    :variant="vStyle.card.variant || undefined"
    :rounded="vStyle.card.rounded || undefined"
    :border="vStyle.card.border || undefined"
  >
    <v-card-title>{{ cardTitle }}</v-card-title>
    <v-card-subtitle>{{ cardSubtitle }}</v-card-subtitle>
    <v-card-text>
      <v-btn
        variant="outlined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        @click="getPdHubUserToken()"
        >{{ buttonText }}</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import moment from "moment";
import { mapGetters } from "vuex";
import { PdHubService } from "@/services/api/pd-hub.service";
import { SpinnerService } from "@/services/spinner.service";
import ToastService from "@/services/toast.service";
import { CompanyConfig } from "../../models/company-config.model";
import { CompanyConfigService } from "../../services/api/company-config.service";

export default defineComponent({
  name: "TokenStatusCard",
  props: {
    companyConfigModel: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      apiService: new CompanyConfigService(),
      pdHubService: PdHubService.getInstance(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      pdHubToken: "pdHubToken",
    }),
    cardTitle(): string {
      const token = this.pdHubToken.token;
      const tokenValidTill = this.pdHubToken.tokenValidTill;

      if (token && tokenValidTill) {
        if (moment().isBefore(moment(tokenValidTill))) {
          return "Aktuell bei PD-Hub angemeldet";
        } else {
          return "Token abgelaufen";
        }
      } else {
        return "Nicht angemeldet";
      }
    },
    cardSubtitle(): string {
      const tokenValidTill = this.pdHubToken.tokenValidTill;

      if (tokenValidTill) {
        if (moment().isBefore(moment(tokenValidTill))) {
          return `Gültig bis ${moment(tokenValidTill).format(
            "DD.MM.YYYY HH:mm"
          )}`;
        } else {
          return `Abgelaufen seit ${moment(tokenValidTill).format(
            "DD.MM.YYYY HH:mm"
          )}`;
        }
      } else {
        return "";
      }
    },
    buttonText(): string {
      const token = this.pdHubToken.token;
      const tokenValidTill = this.pdHubToken.tokenValidTill;

      if (token && tokenValidTill) {
        if (moment().isBefore(moment(tokenValidTill))) {
          return "Neuer Token";
        } else {
          return "Token erneuern";
        }
      } else {
        return "Anmelden";
      }
    },
  },
  methods: {
    async getPdHubUserToken() {
      SpinnerService.showSpinner();
      const companyConfig = this.companyConfigModel;
      try {
        this.pdHubService
          .getUserToken(
            companyConfig.apiKeys.pdHubToken.username,
            companyConfig.apiKeys.pdHubToken.password
          )
          .then((userToken: any) => {
            companyConfig.apiKeys.pdHubToken.token = userToken.Token;
            companyConfig.apiKeys.pdHubToken.tokenValidTill =
              userToken.TokenValidTill;
            this.updateCompanyConfig(companyConfig);
            ToastService.showSuccess("Authorisierung erfolgreich");
          });
      } catch (error) {
        ToastService.showError("Authorisierung fehlgeschlagen");
      }
      SpinnerService.removeSpinner();
    },
    updateCompanyConfig(companyConfig: CompanyConfig) {
      this.apiService
        .putCompanyConfig(companyConfig)
        .then(() => {
          this.$store.commit("UPDATE_COMPANY_CONFIG", this.companyConfigModel);
          ToastService.showSuccess("Einstellungen erfolgreich gespeichert!");
        })
        .catch((err) => {
          console.error(err);
          ToastService.showError("Fehler beim speichern der Einstellugen!");
        });
    },
  },
});
</script>
