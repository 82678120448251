<template>
  <v-container class="fill-height d-flex justify-center align-center">
    <v-row align="center" justify="center">
      <v-col cols="12" md="8" lg="3">
        <v-card
          color="var(--color-item)"
          class="my-10"
          border="sm"
          rounded="lg"
          elevation="4"
        >
          <v-card-item align="center" justify="center">
            <v-img
              width="100%"
              aspect-ratio="1/1"
              cover
              :src="
                appLayout === AppLayout.pdmaster
                  ? appPdMasterLogo
                  : appDispositionerLogo
              "
            ></v-img>
            <!--TODO if we need more templates change this to a templateObject-->
          </v-card-item>
          <v-form class="pa-5" @submit.prevent="authUser">
            <v-divider></v-divider>
            <v-card-title>Login</v-card-title>
            <v-text-field
              variant="outlined"
              v-model="username"
              label="Email"
              type="email"
              required
            ></v-text-field>
            <v-text-field
              variant="outlined"
              v-model="password"
              label="Passwort"
              type="password"
              required
            ></v-text-field>
            <v-row class="d-flex justify-space-between">
              <v-col>
                <a href="#" class="password-forgot">Passwort vergessen?</a>
              </v-col>
              <v-col class="text-right">
                <a :href="Routes.registration" class="register">Registrieren</a>
              </v-col>
            </v-row>
            <v-btn type="submit" color="primary" block class="mt-4 mb-6">
              Einloggen
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { LoginService } from "@/services/api/login.service";
import ToastService from "@/services/toast.service";
import DialogService from "@/services/dialog.service";
import { AppLayout } from "@/enums/app-layout.enum";
import { Routes } from "@/enums/routes.enum";
import { SpinnerService } from "../services/spinner.service";

export default defineComponent({
  name: "LoginView",
  data() {
    return {
      appLayout: this.$store.getters.getEnv.VUE_APP_LAYOUT,
      AppLayout: AppLayout,
      appDispositionerLogo: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/dispositioner-logo-light.svg`,
      appPdMasterLogo: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/pdmaster-logo-light.svg`,
      loginService: new LoginService(),
      Routes: Routes,
      username: "",
      password: "",
      currentRoute: "",
    };
  },
  created() {
    localStorage.removeItem("accessToken");
    this.currentRoute = this.$router.currentRoute.value.path;
  },
  mounted() {
    sessionStorage.removeItem("vuexState");
    this.loginService.logout();
  },
  methods: {
    async authUser() {
      SpinnerService.showSpinner();
      try {
        await this.loginService
          .login({ username: this.username, password: this.password })
          .then(() => {
            this.$store.dispatch("loadStoreInitialData").then(() => {
              this.navigateToDashboard();
              this.$store.dispatch("loadCustomerAndEmployeeBackgroundData");
            });
          });
      } catch (error: any) {
        switch (error.code) {
          case "ERR_BAD_REQUEST":
            ToastService.show("Name oder Passwort fehlerhaft");
            break;
          default:
        }
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    navigateToDashboard() {
      this.$router.push(Routes.dashboard);
    },
    showError(errorText: string) {
      DialogService.alert(errorText);
    },
  },
});
</script>

<style scoped lang="scss">
@import "./scss/styles.scss";

.fill-height {
  height: 100vh;
}
.password-forgot,
.register {
  color: var(--color-primary);
}

.password-forgot:hover,
.register:hover {
  color: var(--color-tertiary);
}
</style>
