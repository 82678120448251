//src/services/api/anzeigendaten.service.ts
import axios from "axios";
import store from "../../store/store";
import moment from "moment";
import {
  IndexJobAd,
  JobLeadsRequestBody,
  JodAdLeadsQuery,
} from "@/models/external/index-anzeigendaten.model";
import ToastService from "../toast.service";
import { CompanyConfigService } from "./company-config.service";
import { ApiConfig, CompanyConfig } from "@/models/company-config.model";
import { IaApiType } from "@/enums/anzeigendaten.enum";
import { JobAdLead } from "@/models/job-ad-lead.model";
import { AnzeigendatenAdapter } from "@/adapter/anzeigendaten.adapter";

export class AnzeigedatenService {
  companyConfigService = CompanyConfigService.getInstance();
  apiKeys = {} as ApiConfig;

  private static instance: AnzeigedatenService;

  constructor() {
    this.companyConfigService
      .getConfig()
      .then((companyConfig: CompanyConfig) => {
        this.apiKeys = companyConfig.apiKeys;
      });
  }

  public static getInstance(): AnzeigedatenService {
    if (!AnzeigedatenService.instance) {
      AnzeigedatenService.instance = new AnzeigedatenService();
    }
    return AnzeigedatenService.instance;
  }

  getBaseURL(apiVersion: string) {
    const apiKeys = this.apiKeys;
    if (apiVersion === IaApiType.auth) {
      return apiKeys.baseURLIndexAuth;
    } else if (apiVersion === IaApiType.customers) {
      return apiKeys.baseUrlIndexCustomers;
    } else {
      return apiKeys.baseURLIndexAds;
    }
  }

  //TODO: Add later searchlogic for mandant and user in the objects
  getToken(mandant?: string, user?: string) {
    const indexAnzeigendaten = this.apiKeys.indexAnzeigendaten;
    const tokenObject = indexAnzeigendaten.find((key) => {
      if (user && key.creditalsForUsersOnly.includes(user)) {
        return true;
      }
      if (mandant && key.creditalsForMandantsOnly.includes(mandant)) {
        return true;
      }
      return (
        key.creditalsForUsersOnly.includes("all") ||
        key.creditalsForMandantsOnly.includes("all")
      );
    });

    if (tokenObject) {
      return tokenObject.TOKEN;
    } else {
      throw new Error("No valid token found");
    }
  }

  async authAndGetIndexApiKey(login: string, password: string) {
    try {
      const response = await axios.post(this.getBaseURL(IaApiType.auth), [
        {
          login: login,
          password: password,
        },
      ]);

      if (response.data.includes("Not understood(LOGIN or PASSWORD).")) {
        ToastService.showError("Login oder Passwort fehlerhaft");
      } else if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Error: Received status code ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching API key:", error);
      throw error;
    }
  }

  async getCustomerFromIndex(postalCode: string, customerName: string) {
    const baseURL = this.getBaseURL(IaApiType.customers); // Endpoint for customer API 3.6
    const requestBody = [
      {
        TOKEN: this.getToken(),
        COMPANY_NAME: customerName,
        COMPANY_POSTAL_CODE: postalCode,
      },
    ];

    try {
      const response = await axios.post(baseURL, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.includes("Not understood(TOKEN)")) {
        throw new Error("ungültiger Token");
      }
      const transformedData = response.data[0]?.EXPORT.RESULT.ITEM.map(
        (item: any, index: number) =>
          AnzeigendatenAdapter.adaptIndexCustomerToCustomer(item, index)
      );

      return transformedData;
    } catch (error) {
      console.debug("Error in getCustomerFromIndex:", error);
      throw error;
    }
  }

  async getJobAdsFromCustomer(indexCompanyId: string): Promise<JobAdLead[]> {
    const baseURL = this.getBaseURL(IaApiType.jodAds);
    const requestBody = [
      {
        TOKEN: this.getToken(),
        COMPANY_ID: indexCompanyId,
        DATE_FROM: moment()
          .subtract(
            store.state.company.softwareIntegration.indexJobAdAgeLimitDays,
            "days"
          )
          .format("DD.MM.YYYY"),
      },
    ];

    try {
      const response = await axios.post(baseURL, requestBody, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data.includes("Not understood(TOKEN)")) {
        throw new Error("ungültiger Token");
      }

      const items = response.data[0].EXPORT.RESULT.ITEM;

      if (items && Array.isArray(items)) {
        const jobAds = items.map((item: IndexJobAd) =>
          AnzeigendatenAdapter.adaptIndexJobAdToJobAd(item)
        );
        return jobAds;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error in getJobAdsFromCustomer:", error);
      throw error;
    }
  }

  async getLeads(
    query: JodAdLeadsQuery,
    differentPeriod?: number
  ): Promise<JobAdLead[]> {
    const baseURL = this.getBaseURL(IaApiType.jodAds);
    let indexJobLeadsAgeLimitDays =
      store.state.company.softwareIntegration.indexJobLeadsAgeLimitDays;

    if (differentPeriod) {
      indexJobLeadsAgeLimitDays = differentPeriod;
    }

    let requestBody: JobLeadsRequestBody = {
      TOKEN: this.getToken(),
      JOB_TITLE: query.JOB_TITLE,
      AI_SEARCH: "yes",
      POSTAL_CODE: query.POSTAL_CODE,
      SURROUNDING_REGION: query.SURROUNDING_REGION ?? "5",
      EXCLUDE_EMPLOYMENT_AGENCIES: "yes",
      DATE_FROM: moment()
        .subtract(indexJobLeadsAgeLimitDays, "days")
        .format("DD.MM.YYYY"),
      NUMBER_OF_ITEMS:
        store.state.company.softwareIntegration.indexJobLeadsMaxAds.toString(),
      JOB_POSTING: query.JOB_POSTING,
      CAREER_LEVEL: query.CAREER_LEVEL,
    };

    const attempts = [
      { remove: "CAREER_LEVEL" },
      { remove: "JOB_POSTING" },
      { replace: { SURROUNDING_REGION: "50" } },
      { final: true },
    ];

    for (const attempt of attempts) {
      try {
        const response = await axios.post(baseURL, [requestBody], {
          headers: { "Content-Type": "application/json" },
        });

        if (response.data.includes("Not understood(TOKEN)")) {
          throw new Error("ungültiger Token");
        }

        if (response.data[0].EXPORT.RESULT === "NOT FOUND" && !attempt.final) {
          if (attempt.remove) {
            delete requestBody[attempt.remove];
          }
          if (attempt.replace) {
            requestBody = { ...requestBody, ...attempt.replace };
          }
        } else if (
          response.data[0].EXPORT.RESULT === "NOT FOUND" &&
          attempt.final
        ) {
          console.error("Final attempt failed. No more retries.");
          return [
            {
              retrievalDate: moment().format("DD.MM.YYYY"),
              jobTitle: "keine Anzeigen gefunden",
              jobAdText: "",
            } as JobAdLead,
          ];
        } else {
          const items = response.data[0].EXPORT.RESULT.ITEM;
          if (items && Array.isArray(items)) {
            const jobAds = items.map((item: IndexJobAd) =>
              AnzeigendatenAdapter.adaptIndexJobAdToJobAd(item)
            );
            return jobAds;
          }
        }
      } catch (error) {
        if (attempt.final) {
          throw error;
        }
      }
    }
    return [];
  }
}
