<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-link</v-icon>Konfiguration
      für PD-Hub
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="9"></v-col>
        <v-col cols="12" md="3">
          <v-switch
            color="primary"
            v-model="localConfig.softwareIntegration.erpAutoDocu"
            label="automatische Dokumentation"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.pdHubBaseUrl"
            label="Base URL PD-Hub"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.pdHubCustomerId"
            label="CustomerId"
          ></v-text-field>
        </v-col>
        <v-col v-if="isUberAdmin" cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.pdHubClientId"
            label="Client Id"
          ></v-text-field>
        </v-col>
        <v-col v-if="isUberAdmin" cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.pdHubClientSecret"
            label="Client Secret"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-form>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="localConfig.apiKeys.pdHubToken.username"
              label="Username"
            ></v-text-field>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="localConfig.apiKeys.pdHubToken.password"
              label="Passwort"
              type="password"
            ></v-text-field>
          </v-form>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.pdHubL1Mandant"
            label="L1 Mandant"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <TokenStatusCard :companyConfigModel="localConfig"></TokenStatusCard>
        </v-col>
      </v-row>
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        class="mt-10"
        type="submit"
        color="success"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";
import TokenStatusCard from "@/components/modals/TokenStatusCard.vue";
import { User } from "@/models/user.model";
import { UserHelperService } from "@/services/user-helper.service";
import { UserRoleHelper } from "@/helper/user-role.helper";

export default defineComponent({
  name: "ConfigPdHub",
  emits: ["update:modelValue", "save"],
  components: {
    TokenStatusCard,
  },
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
      user: {} as User | any,
      userHelperService: UserHelperService.getInstance(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    isUberAdmin(): boolean {
      return UserRoleHelper.isUberAdmin(this.user);
    },
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      this.user = this.$store.getters.user;
    },
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>
