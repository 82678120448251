<!--src/components/payflow/PayFlowOverview.vue-->
<template>
  <div class="mt-5 d-flex align-end">
    <v-img
      :src="logoPayFlow"
      aspect-ratio="1"
      cover
      alt="PayFlow Icon"
      max-width="6rem"
      class="ml-4 mr-6"
      ><v-tooltip activator="parent" location="bottom"
        >PayFlow: Lohnlaufinfo und Vorschussliste</v-tooltip
      >
    </v-img>
    <v-spacer></v-spacer>
    <v-select
      variant="plain"
      :items="payFlowRoleOptions"
      item-title="label"
      item-value="label"
      v-model="currentPayFlowRole"
      label="Rolle"
    ></v-select>
    <v-spacer></v-spacer>
    <v-select
      variant="plain"
      :items="payrollInfoTypeOptions"
      item-title="label"
      item-value="label"
      v-model="currentPayrollInfoType"
      label="Vorschussliste / Lohnlaufinfo"
      @update:model-value="loadPayFlows()"
    ></v-select>
    <v-spacer></v-spacer>
    <v-select
      variant="plain"
      :items="mandants"
      item-title="branchInitials"
      item-value="uuid"
      v-model="selectedMandants"
      label="Niederlassungen"
      multiple
      @update:model-value="loadPayFlows()"
    ></v-select>
    <v-spacer></v-spacer>
    <v-select
      variant="plain"
      :items="payrollMonths"
      v-model="currentPayrollMonth"
      label="Abrechnungsmonat"
    ></v-select>
  </div>
  <div class="my-5 d-flex justify-space-between">
    <v-btn
      :variant="vStyle.btn.variant || undefined"
      :rounded="vStyle.btn.rounded || undefined"
      :border="vStyle.btn.border || undefined"
      @click="exportBankDetailsAsCSV()"
      >offene Überweisungen exportieren<v-icon class="ml-1"
        >fa-solid fa-file-csv</v-icon
      ></v-btn
    >
    <v-btn
      :variant="vStyle.btn.variant || undefined"
      :rounded="vStyle.btn.rounded || undefined"
      :border="vStyle.btn.border || undefined"
      @click="exportPayFlowListAsCSV()"
      >Liste exportieren<v-icon class="ml-1"
        >fa-solid fa-file-csv</v-icon
      ></v-btn
    >
    <!--TODO: <v-btn>Alles ins ERP Laden</v-btn>-->
  </div>
  <v-list-item v-for="payFlow in payFlows" :key="payFlow._id">
    <v-row class="mr-5">
      <v-col cols="12" md="3">
        <div class="mt-1 d-flex font-weight-medium first-line">
          <div v-if="isPayFlowRecordedInErp(payFlow)">
            <v-badge
              color="success"
              :style="{ scale: 0.6 }"
              content="✔"
              overlap
              offset-x="0"
              offset-y="-5"
              class="mr-1"
            >
              <v-icon class="text-medium-emphasis"
                >fas fa-cloud-arrow-up</v-icon
              >
            </v-badge>
            <v-tooltip activator="parent" location="end">
              Buchung im ERP ist erfolgt
            </v-tooltip>
          </div>
          <div
            class="copyable"
            @click="copyToClipboard(`${payFlow.firstName} ${payFlow.lastName}`)"
          >
            {{ payFlow.firstName }} {{ payFlow.lastName }} ({{
              payFlow.employeeNumber
            }})
          </div>
        </div>
        <div class="d-flex mt-1 text-medium-emphasis second-line">
          <div v-if="isPayFlowCompleted(payFlow)">
            <v-badge
              color="success"
              :style="{ scale: 0.6 }"
              content="✔"
              overlap
              offset-x="0"
              offset-y="-5"
              class="mr-1"
            >
              <v-icon class="text-medium-emphasis"
                >fas fa-money-bill-wave</v-icon
              >
            </v-badge>
            <v-tooltip activator="parent" location="end">
              Abgeschlossen
            </v-tooltip>
          </div>
          <div v-if="payFlow.bankAccount?.iban">
            IBAN:
            <strong
              class="ml-1 copyable"
              @click="copyToClipboard(payFlow.bankAccount?.iban)"
              >{{ payFlow.bankAccount?.iban }}</strong
            >
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <div class="d-flex first-line">
          <v-icon
            class="text-medium-emphasis mt-1 mr-1"
            v-if="payFlow.info"
            size="xsmall"
            >fas fa-circle-info</v-icon
          >
          {{ payFlow.info ? "" || payFlow.info : "" }}
        </div>
        <div class="d-flex first-line">
          <div class="mr-3" v-if="payFlow.bankAccount?.differentAccountHolder">
            abweichender Kontoinhaber:
            <strong
              class="ml-1 copyable"
              @click="
                copyToClipboard(payFlow.bankAccount?.differentAccountHolder)
              "
              :style="{ color: 'var(--color-alert)' }"
            >
              {{ payFlow.bankAccount?.differentAccountHolder }}</strong
            >
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="2">
        <div class="d-flex font-weight-medium first-line">
          <strong
            class="mr-1 copyable"
            @click="
              copyToClipboard(`${payFlow.infoType} Lohn ${currentPayrollMonth}`)
            "
            >{{ payFlow.infoType }} {{ payFlow.miscellaneous }}</strong
          >
          {{ payFlow.monthly ? payFlow.payrollMonth || "monatlich" : "" }}
        </div>
        <div class="d-flex font-weight-medium second-line">
          <div class="mr-1" v-if="payFlow.monetaryAmount">
            Betrag:
            <strong
              class="ml-1 copyable"
              @click="copyToClipboard(payFlow.monetaryAmount)"
              >{{ payFlow.monetaryAmount }}</strong
            >€
          </div>
          <div class="mr-1" v-if="payFlow.hours">
            <strong>{{ payFlow.hours }}</strong> Stunden
          </div>
          <div class="d-flex" v-if="payFlow.dateFrom">
            {{ formatDate(payFlow.dateFrom) }}
            <div class="mx-1" v-if="payFlow.dateTo">
              bis {{ formatDate(payFlow.dateTo) }}
            </div>
          </div>
        </div>
      </v-col>
      <v-col class="d-flex align-center" cols="12" md="1">
        <v-list-item-action v-if="payFlow?._id" class="text-high-emphasis">
          <v-btn
            icon
            variant="text"
            density="compact"
            @click="editPayFlow(payFlow)"
            :disabled="isPayFlowCompleted(payFlow)"
          >
            <v-icon size="xs">fas fa-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            variant="text"
            density="compact"
            @click="removePayFlow(payFlow._id)"
            :disabled="isPayFlowCompleted(payFlow)"
          >
            <v-icon size="xs">fas fa-trash-can</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-col>
      <v-col class="d-flex align-center" cols="12" md="1">
        <div v-if="getInfoCheckIcon(payFlow)">
          <v-icon size="xsmall">{{ getInfoCheckIcon(payFlow) }}</v-icon>
          <v-tooltip activator="parent" location="end">
            {{ payFlow.infoCheck }}
          </v-tooltip>
        </div>
        <v-btn
          @click="checkEmployee(payFlow)"
          :disabled="
            isPayFlowCheckAccepted(payFlow) || isPayFlowCompleted(payFlow)
          "
          variant="text"
          icon
          size="small"
        >
          <v-icon>fa-solid fa-magnifying-glass</v-icon>
          <v-tooltip activator="parent" location="end">
            auf Austritt / Einsatz prüfen
          </v-tooltip></v-btn
        >
      </v-col>
      <v-col class="d-flex align-center" cols="12" md="1">
        <div v-for="role in checkedByRoles" :key="role">
          <div v-if="getBadgeConfig(payFlow, role)" class="d-flex">
            <v-badge
              :color="getBadgeConfig(payFlow, role)?.color"
              :style="{ scale: 0.8 }"
              :content="getBadgeConfig(payFlow, role)?.content"
              overlap
              offset-x="0"
              offset-y="-5"
              class="mx-1"
            >
              <v-icon class="text-medium-emphasis">
                {{ getBadgeConfig(payFlow, role)?.icon }}
              </v-icon>
            </v-badge>
            <v-tooltip activator="parent" location="end">
              {{ getBadgeConfig(payFlow, role)?.tooltip }}
            </v-tooltip>
          </div>
        </div>
      </v-col>
      <v-col class="d-flex align-center" cols="12" md="1">
        <v-btn
          @click="permitPayFlow(payFlow, currentPayFlowRole)"
          :disabled="
            isPayFlowCheckAccepted(payFlow) || isPayFlowCompleted(payFlow)
          "
          variant="text"
          icon
          size="small"
          color="success"
        >
          <v-icon>fas fa-circle-check</v-icon>
          <v-tooltip activator="parent" location="end">
            Genehmigen ({{ currentPayFlowRole }})
          </v-tooltip></v-btn
        >
        <v-btn
          v-if="currentPayFlowRole !== PayFlowRole.BranchManager"
          @click="rejectPayFlow(payFlow, currentPayFlowRole)"
          :disabled="
            isPayFlowCheckRejected(payFlow) || isPayFlowCompleted(payFlow)
          "
          variant="text"
          icon
          size="small"
          color="abort"
        >
          <v-icon>fas fa-circle-xmark</v-icon>
          <v-tooltip activator="parent" location="end">
            Ablehnen ({{ currentPayFlowRole }})
          </v-tooltip></v-btn
        >
        <v-btn
          v-if="currentPayFlowRole !== PayFlowRole.BranchManager"
          @click="sendPayFlowToErp(payFlow)"
          :disabled="!isPayFlowUploadable(payFlow)"
          variant="text"
          icon
          size="small"
        >
          <v-icon>fas fa-cloud-arrow-up</v-icon>
          <v-tooltip activator="parent" location="end">
            im ERP System buchen
          </v-tooltip></v-btn
        >
        <v-btn
          v-if="currentPayFlowRole === PayFlowRole.PayrollOfficer"
          @click="markPayFlowAsCompleted(payFlow)"
          :disabled="!isPayFlowCompletable(payFlow)"
          variant="text"
          icon
          size="small"
          color="success"
        >
          <v-icon>fas fa-money-bill-wave</v-icon>
          <v-tooltip activator="parent" location="end">
            auf 'abgeschlossen' stellen {{ isPayFlowCompleted(payFlow) }}
          </v-tooltip></v-btn
        >
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </v-list-item>
  <DialogAddEditPayFlow
    ref="dialogAddEditPayFlowComponent"
    :payFlow="currentPayFlow"
    :isEdit="editCurrentPayFlow"
    @updatePayFlow="updateAndMarkPayFlow"
  ></DialogAddEditPayFlow>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PayFlow } from "@/models/payflow.model";
import { PayFlowService } from "@/services/api/payflow.service";
import DialogAddEditPayFlow from "./DialogAddEditPayFlow.vue";
import DialogService from "@/services/dialog.service";
import moment from "moment";
import ToastService from "@/services/toast.service";
import { getEnumOptions } from "@/helper/enum.helper";
import {
  PayFlowRole,
  PayFlowType,
  PayFlowCheck,
  PayrollInfoType,
  PayrollDeduction,
} from "@/enums/payflow.enum";
import { UserHelperService } from "@/services/user-helper.service";
import { ZvooveOneEmployee } from "@/services/api/api-integration-one.service";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "PayFlowOverview",
  components: {
    DialogAddEditPayFlow,
  },
  data() {
    return {
      checkedByRoles: ["branchManager", "accountant", "payrollOfficer"],
      currentPayFlow: {} as PayFlow,
      currentPayFlowRole: PayFlowRole.BranchManager,
      currentPayrollMonth: moment().format("YYYY-MM"),
      currentPayrollInfoType: PayFlowType.all,
      editCurrentPayFlow: false,
      logoPayFlow: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/payflow-logo-light.svg`,
      payFlows: [] as PayFlow[],
      PayFlowRole: PayFlowRole,
      payFlowRoleOptions: [] as PayFlowRole[],
      payFlowService: new PayFlowService(),
      payrollInfoTypeOptions: getEnumOptions(PayFlowType),
      selectedMandants: [] as string[],
      vStyle: this.$store.state.vStyle,
      zvooveOneEmployee: new ZvooveOneEmployee(),
    };
  },
  computed: {
    ...mapGetters({
      mandants: "reducedMandants",
      softwareIntegration: "softwareIntegration",
    }),
    payrollMonths(): string[] {
      const months = [];
      for (let i = 1; i > 0; i--) {
        months.push(moment().subtract(i, "months").format("YYYY-MM"));
      }
      months.push(moment().format("YYYY-MM"));
      months.push(moment().add(1, "months").format("YYYY-MM"));
      return months;
    },
  },
  mounted() {
    this.loadPayFlows();
    this.setPayFlowRolesBasedOnUserRole();
  },
  watch: {
    currentPayrollMonth() {
      this.loadPayFlows();
    },
  },
  methods: {
    async checkEmployee(payFlow: PayFlow) {
      const response = await this.getExitDateAndLastPlacementIfExists(
        payFlow.employeeNumber
      );

      const today = moment();
      let warningMessage = "";

      if (response.exitDate && response.exitDate !== "undefined") {
        const exitDate = moment(response.exitDate);
        if (exitDate.isValid()) {
          const daysUntilExit = exitDate.diff(today, "days");
          if (daysUntilExit < 0) {
            warningMessage += `Mitarbeiter ist bereits ausgetreten (am ${exitDate.format(
              "DD.MM.YYYY"
            )}). `;
          } else if (daysUntilExit <= 30) {
            warningMessage += `Austritt in ${daysUntilExit} Tagen (am ${exitDate.format(
              "DD.MM.YYYY"
            )}). `;
          }
        }
      }

      if (
        response.lastPlacementEndDate &&
        response.lastPlacementEndDate !== null
      ) {
        const lastPlacementEndDate = moment(response.lastPlacementEndDate);
        if (lastPlacementEndDate.isValid()) {
          const daysUntilLastPlacementEnd = lastPlacementEndDate.diff(
            today,
            "days"
          );
          if (daysUntilLastPlacementEnd < 0) {
            warningMessage += `Letzter Einsatz ist bereits beendet (am ${lastPlacementEndDate.format(
              "DD.MM.YYYY"
            )}). `;
          } else if (daysUntilLastPlacementEnd <= 30) {
            warningMessage += `Letzter Einsatz endet in ${daysUntilLastPlacementEnd} Tagen (am ${lastPlacementEndDate.format(
              "DD.MM.YYYY"
            )}). `;
          }
        }
      } else {
        warningMessage +=
          "Kein laufender oder bevorstehender Einsatz vorhanden. ";
      }

      const checkDate = moment().format("DD.MM.YYYY HH:mm");
      if (warningMessage) {
        ToastService.showError(warningMessage.trim());
        payFlow.infoCheck = `⚠ ${checkDate}: ${warningMessage.trim()} `;
        this.updatePayFlow(payFlow, true);
      } else {
        ToastService.showSuccess(
          "Kein Austritt und Einsatzende < 30 Tage im System!"
        );
        payFlow.infoCheck = `✔ ${checkDate}`;
        this.updatePayFlow(payFlow, true);
      }
    },
    async copyToClipboard(text: string | undefined) {
      if (text) {
        try {
          await navigator.clipboard.writeText(text);
          ToastService.showSuccess(`'${text}' ins Clipboard kopiert!`);
        } catch (error) {
          ToastService.showError("Fehler beim Kopieren ins Clipboard!");
          console.error("Clipboard Error:", error);
        }
      }
    },
    editPayFlow(payFlow: PayFlow) {
      this.currentPayFlow = payFlow;
      this.editCurrentPayFlow = true;
      if (this.$refs.dialogAddEditPayFlowComponent) {
        (
          this.$refs.dialogAddEditPayFlowComponent as InstanceType<
            typeof DialogAddEditPayFlow
          >
        ).openModal();
      }
    },
    escapeCsvValue(value: string): string {
      if (!value) return "";
      return value.includes(";") || value.includes('"')
        ? `"${value.replace(/"/g, '""')}"`
        : value;
    },
    exportBankDetailsAsCSV() {
      const filteredPayFlows = this.payFlows.filter((payFlow) => {
        const checkedItem = payFlow.checked.find(
          (item) => item.payrollMonth === this.currentPayrollMonth
        );
        return (
          checkedItem &&
          checkedItem.payrollOfficer === PayFlowCheck.accepted &&
          (payFlow.infoType === PayrollInfoType.advance ||
            payFlow.infoType === PayrollInfoType.partialPayment) &&
          payFlow.bankAccount?.iban &&
          !checkedItem.completed
        );
      });

      if (filteredPayFlows.length === 0) {
        ToastService.showError("Keine Daten für den Export gefunden!");
        return;
      }

      const csvData = filteredPayFlows.map((payFlow) => {
        const accountHolder =
          payFlow.bankAccount?.differentAccountHolder ||
          `${payFlow.firstName} ${payFlow.lastName}`;

        const formattedAmount = parseFloat(payFlow.monetaryAmount || "0")
          .toFixed(2)
          .replace(".", ",");

        let usagePurpose = `${payFlow.infoType} ${this.currentPayrollMonth}`;
        if (payFlow.bankAccount?.differentAccountHolder) {
          usagePurpose += ` - ${payFlow.firstName} ${payFlow.lastName}`;
        }

        return {
          accountHolder: accountHolder,
          iban: payFlow.bankAccount?.iban || "",
          amount: formattedAmount,
          usagePurpose: usagePurpose,
        };
      });

      const csvHeader = ["Empfängername", "IBAN", "Betrag", "Verwendungszweck"];
      const csvContent =
        csvHeader.join(";") +
        "\n" +
        csvData
          .map((row) => {
            return [
              this.escapeCsvValue(row.accountHolder),
              row.iban,
              row.amount,
              this.escapeCsvValue(row.usagePurpose),
            ].join(";");
          })
          .join("\n");

      const bom = "\uFEFF";
      const blob = new Blob([bom + csvContent], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute(
        "download",
        `bank_details_${this.currentPayrollMonth}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      ToastService.showSuccess("CSV-Datei erfolgreich exportiert!");
    },
    exportPayFlowListAsCSV() {
      const csvData = this.payFlows.map((payFlow) => {
        const accountHolder =
          payFlow.bankAccount?.differentAccountHolder ||
          `${payFlow.firstName} ${payFlow.lastName}`;
        const monetaryAmount = parseFloat(payFlow.monetaryAmount || "0")
          .toFixed(2)
          .replace(".", ",");
        const infoType = `${payFlow.infoType} ${this.currentPayrollMonth}`;
        const dates = payFlow.dateFrom
          ? `${this.formatDate(payFlow.dateFrom)}${
              payFlow.dateTo ? " bis " + this.formatDate(payFlow.dateTo) : ""
            }`
          : "";

        return {
          Empfängername: accountHolder,
          IBAN: payFlow.bankAccount?.iban || "",
          Betrag: `${monetaryAmount}€`,
          Lohnlaufinfo: infoType,
          Einsatzzeitraum: dates,
          Stunden: payFlow.hours || "",
          Mitarbeiternummer: payFlow.employeeNumber,
          Abrechnungsmonat: payFlow.payrollMonth || this.currentPayrollMonth,
          Info: payFlow.info || "",
          "Kontoinhaber (abweichend)":
            payFlow.bankAccount?.differentAccountHolder || "",
          "Erfasst im ERP": this.isPayFlowRecordedInErp(payFlow)
            ? "Ja"
            : "Nein",
          Abgeschlossen: this.isPayFlowCompleted(payFlow) ? "Ja" : "Nein",
        };
      });

      const csvHeader = [
        "Empfängername",
        "IBAN",
        "Betrag",
        "Lohnlaufinfo",
        "Einsatzzeitraum",
        "Stunden",
        "Mitarbeiternummer",
        "Abrechnungsmonat",
        "Info",
        "Kontoinhaber (abweichend)",
        "Erfasst im ERP",
        "Abgeschlossen",
      ];

      const csvContent =
        csvHeader.join(";") +
        "\n" +
        csvData
          .map((row) =>
            csvHeader
              .map((header) =>
                this.escapeCsvValue(row[header as keyof typeof row])
              )
              .join(";")
          )
          .join("\n");

      const bom = "\uFEFF";
      const blob = new Blob([bom + csvContent], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute(
        "download",
        `payflow_list_${this.currentPayrollMonth}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      ToastService.showSuccess("PayFlow-Liste erfolgreich exportiert!");
    },

    formatDate(date: string) {
      return moment(date).format("DD.MM.YYYY");
    },
    filterPayFlows(payFlows: PayFlow[]): PayFlow[] {
      return payFlows.filter((payFlow) => {
        if (this.currentPayrollInfoType === PayFlowType.all) {
          return true;
        }
        if (
          this.currentPayrollInfoType === PayFlowType.advanceList &&
          (payFlow.infoType === PayrollInfoType.advance ||
            payFlow.infoType === PayrollInfoType.partialPayment)
        ) {
          return true;
        }
        if (
          this.currentPayrollInfoType === PayFlowType.payrollInfo &&
          payFlow.infoType !== PayrollInfoType.advance &&
          payFlow.infoType !== PayrollInfoType.partialPayment
        ) {
          return true;
        }
        return false;
      });
    },
    getBadgeConfig(payFlow: PayFlow, role: string) {
      const checkedItem = payFlow.checked.find(
        (item) => item.payrollMonth === this.currentPayrollMonth
      );

      if (!checkedItem) return null;

      const checkStatus = checkedItem[role as keyof typeof checkedItem];

      switch (checkStatus) {
        case PayFlowCheck.accepted:
          return {
            color: "success",
            content: "✔",
            icon: this.getRoleIcon(role),
            tooltip: `von ${this.getRoleName(role)} geprüft`,
          };
        case PayFlowCheck.edited:
          return {
            color: "yellow-darken-1",
            content: "✎",
            icon: this.getRoleIcon(role),
            tooltip: `von ${this.getRoleName(role)} bearbeitet`,
          };
        case PayFlowCheck.rejected:
          return {
            color: "abort",
            content: "✖",
            icon: this.getRoleIcon(role),
            tooltip: `von ${this.getRoleName(role)} abgelehnt`,
          };
        default:
          return null;
      }
    },
    async getExitDateAndLastPlacementIfExists(employeeNumber: string) {
      const response = (await this.zvooveOneEmployee.getEmployeeAndPlacement(
        employeeNumber
      )) as any;

      const exitDate = response.exitDate;
      const placements = Object.keys(response)
        .filter((key) => !isNaN(Number(key)))
        .map((key) => response[key]);
      const lastPlacement = placements.sort(
        (a, b) => new Date(b.to).getTime() - new Date(a.to).getTime()
      )[0];
      const lastPlacementEndDate = lastPlacement ? lastPlacement.to : null;

      return {
        exitDate,
        lastPlacementEndDate,
      };
    },
    getInfoCheckIcon(payFlow: PayFlow): string {
      if (!payFlow.infoCheck) return "";

      const firstChar = payFlow.infoCheck.charAt(0);

      if (firstChar === "⚠") {
        return "fa-solid fa-triangle-exclamation";
      } else if (firstChar === "✔") {
        return "fa-solid fa-square-check";
      }

      return "";
    },
    getOrCreateCheckedItem(payFlow: PayFlow): any {
      let checkedItem = payFlow.checked.find(
        (item) => item.payrollMonth === this.currentPayrollMonth
      );

      if (!checkedItem) {
        checkedItem = {
          payrollMonth: this.currentPayrollMonth,
          branchManager: PayFlowCheck.none,
          accountant: PayFlowCheck.none,
          payrollOfficer: PayFlowCheck.none,
          recordedInErp: false,
          completed: false,
        };
        payFlow.checked.push(checkedItem);
      }

      return checkedItem;
    },
    getRoleIcon(role: string) {
      switch (role) {
        case "branchManager":
          return "fas fa-building-user";
        case "accountant":
          return "fas fa-scale-balanced";
        case "payrollOfficer":
          return "fas fa-user-tie";
        default:
          return "";
      }
    },
    getRoleKey(
      payFlowRole: PayFlowRole
    ): "branchManager" | "accountant" | "payrollOfficer" {
      switch (payFlowRole) {
        case PayFlowRole.PayrollOfficer:
          return "payrollOfficer";
        case PayFlowRole.Accountant:
          return "accountant";
        case PayFlowRole.BranchManager:
          return "branchManager";
        default:
          throw new Error(`Unknown payFlowRole: ${payFlowRole}`);
      }
    },
    getRoleName(role: string) {
      switch (role) {
        case "branchManager":
          return "Niederlassungsleitung";
        case "accountant":
          return "Buchhaltung";
        case "payrollOfficer":
          return "Lohnauszahlung";
        default:
          return "";
      }
    },
    isPayFlowRecordedInErp(payFlow: PayFlow) {
      const checkedItem = payFlow.checked.find(
        (item) =>
          item.recordedInErp === true &&
          item.payrollMonth === this.currentPayrollMonth
      );

      return !!checkedItem;
    },
    isPayFlowCompleted(payFlow: PayFlow) {
      const checkedItem = payFlow.checked.find(
        (item) =>
          item.completed === true &&
          item.payrollMonth === this.currentPayrollMonth
      );

      return !!checkedItem;
    },
    isPayFlowCompletable(payFlow: PayFlow) {
      const checkedItem = payFlow.checked.find(
        (item) =>
          this.isPayFlowRecordedInErp(payFlow) === true &&
          this.isPayFlowCompleted(payFlow) === false &&
          item.payrollMonth === this.currentPayrollMonth
      );

      return !!checkedItem;
    },
    isPayFlowUploadable(payFlow: PayFlow) {
      const checkedItem = payFlow.checked.find(
        (item) =>
          this.isPayFlowCheckAccepted(payFlow) === true &&
          this.isPayFlowRecordedInErp(payFlow) === false &&
          this.isPayFlowCompleted(payFlow) === false &&
          item.payrollMonth === this.currentPayrollMonth
      );

      return !!checkedItem;
    },
    isPayFlowCheckAccepted(payFlow: PayFlow) {
      const roleKey = this.getRoleKey(this.currentPayFlowRole);
      const checkedItem = payFlow.checked.find(
        (item) =>
          item[roleKey] === PayFlowCheck.accepted &&
          item.payrollMonth === this.currentPayrollMonth
      );

      return !!checkedItem;
    },
    isPayFlowCheckRejected(payFlow: PayFlow) {
      const roleKey = this.getRoleKey(this.currentPayFlowRole);
      const checkedItem = payFlow.checked.find(
        (item) =>
          item[roleKey] === PayFlowCheck.rejected &&
          item.payrollMonth === this.currentPayrollMonth
      );

      return !!checkedItem;
    },
    async loadPayFlows() {
      try {
        const response = await this.payFlowService.getAllByPayrollMonth(
          this.currentPayrollMonth,
          this.selectedMandants.length === 0 ? undefined : this.selectedMandants
        );
        this.payFlows = this.filterPayFlows(response);
      } catch (error) {
        ToastService.showError("Fehler beim Laden der PayFlows!");
        console.error("Error loading PayFlows:", error);
      }
    },
    async markPayFlowAsCompleted(payFlow: PayFlow) {
      const checkedItem = payFlow.checked.find(
        (item) => item.payrollMonth === this.currentPayrollMonth
      );

      if (checkedItem) {
        checkedItem.completed = true;

        await this.updatePayFlow(payFlow);

        ToastService.showSuccess(
          `PayFlow für den Monat ${this.currentPayrollMonth} erfolgreich als abgeschlossen markiert!`
        );
      } else {
        console.warn(
          `Kein entsprechendes Payroll-Month-Item gefunden für ${this.currentPayrollMonth}`
        );
      }
    },
    async markPayFlowAsRecorded(payFlow: PayFlow) {
      const checkedItem = payFlow.checked.find(
        (item) => item.payrollMonth === this.currentPayrollMonth
      );

      if (checkedItem) {
        checkedItem.recordedInErp = true;

        await this.updatePayFlow(payFlow);
      } else {
        console.warn(
          `Kein entsprechendes Payroll-Month-Item gefunden für ${this.currentPayrollMonth}`
        );
      }
    },
    markPayFlowEdited(payFlow: PayFlow, payFlowRole: string) {
      const checkedItem = this.getOrCreateCheckedItem(payFlow);

      switch (payFlowRole) {
        case PayFlowRole.BranchManager:
          checkedItem.branchManager = PayFlowCheck.edited;
          break;
        case PayFlowRole.Accountant:
          checkedItem.accountant = PayFlowCheck.edited;
          break;
        case PayFlowRole.PayrollOfficer:
          checkedItem.payrollOfficer = PayFlowCheck.edited;
          break;
        default:
          console.warn(`Unknown payFlowRole: ${payFlowRole}`);
      }
      return payFlow;
    },
    permitPayFlow(payFlow: PayFlow, payFlowRole: string) {
      const checkedItem = this.getOrCreateCheckedItem(payFlow);

      switch (payFlowRole) {
        case PayFlowRole.BranchManager:
          checkedItem.branchManager = PayFlowCheck.accepted;
          break;
        case PayFlowRole.Accountant:
          checkedItem.accountant = PayFlowCheck.accepted;
          break;
        case PayFlowRole.PayrollOfficer:
          checkedItem.payrollOfficer = PayFlowCheck.accepted;
          break;
        default:
          console.warn(`Unknown payFlowRole: ${payFlowRole}`);
      }
      this.updatePayFlow(payFlow);
    },
    rejectPayFlow(payFlow: PayFlow, payFlowRole: string) {
      const checkedItem = this.getOrCreateCheckedItem(payFlow);

      switch (payFlowRole) {
        case PayFlowRole.BranchManager:
          checkedItem.branchManager = PayFlowCheck.rejected;
          break;
        case PayFlowRole.Accountant:
          checkedItem.accountant = PayFlowCheck.rejected;
          break;
        case PayFlowRole.PayrollOfficer:
          checkedItem.payrollOfficer = PayFlowCheck.rejected;
          break;
        default:
          console.warn(`Unknown payFlowRole: ${payFlowRole}`);
      }
      this.updatePayFlow(payFlow);
    },
    async removePayFlow(id: string | undefined) {
      if (!id) return;
      const confirmed = await DialogService.confirm(
        "PayFlow wirklich löschen?",
        "Abbrechen",
        "Löschen"
      );
      if (!confirmed) {
        return;
      }
      try {
        const result = await this.payFlowService.removePayFlow(id);
        if (result) {
          this.payFlows = this.payFlows.filter((payFlow) => payFlow._id !== id);
          ToastService.showSuccess("PayFlow erfolgreich gelöscht!");
          if (this.$refs.dialogAddEditPayFlowComponent) {
            (
              this.$refs.dialogAddEditPayFlowComponent as InstanceType<
                typeof DialogAddEditPayFlow
              >
            ).closeModal();
          }
          this.loadPayFlows();
        }
      } catch (error) {
        ToastService.showError("Fehler beim Löschen des PayFlows!");
        console.error("Error removing PayFlow:", error);
      }
    },
    async sendPayFlowToErp(payFlow: PayFlow) {
      const payrollTime = `${this.currentPayrollMonth}-01`;

      const salaryAdjustment = this.$store.getters.getSalaryAdjustment(
        payFlow.infoType
      );

      const payrollDeduction = {
        adjustmentTypeId: salaryAdjustment.adjustmentTypeId,
        type: salaryAdjustment.type,
        payrollPeriodFrom: payrollTime,
        payrollPeriodTo: payrollTime,
        interval: "",
        amount: payFlow.monetaryAmount ?? 0,
        accountNumber: salaryAdjustment.accountNumber,
        info: payFlow.info ?? "",
      } as PayrollDeduction;

      if (this.$store.state.company.softwareIntegration.zvooveOne) {
        try {
          const response = await this.zvooveOneEmployee.createPayrollDeduction(
            payFlow.employeeNumber,
            payrollDeduction
          );

          if (response.status === 204) {
            this.markPayFlowAsRecorded(payFlow);
            ToastService.showSuccess(
              "PayFlow erfolgreich im ERP System gebucht und aktualisiert!"
            );
          } else {
            ToastService.showError(
              "Fehler beim Buchen im ERP System, unerwarteter Statuscode."
            );
            console.error("Unerwarteter Statuscode:", response.status);
          }
        } catch (error) {
          ToastService.showError("Fehler beim Buchen im ERP System!");
          console.error("Error creating payroll deduction:", error);
        }
      } else {
        const confirmed = await DialogService.confirm(
          "Dein ERP System ist noch nicht für PayFlow integriert. Stattdessen die Lohndaten manuell eintragen?",
          "Abbrechen",
          "Ist eingetragen, weiter"
        );

        if (confirmed) {
          this.markPayFlowAsRecorded(payFlow);
          ToastService.showSuccess(
            "PayFlow erfolgreich im ERP System gebucht und aktualisiert!"
          );
        }
      }
    },
    setPayFlowRolesBasedOnUserRole() {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole() as number;

      if (userRole < 7) {
        this.payFlowRoleOptions = [
          PayFlowRole.BranchManager,
          PayFlowRole.Accountant,
          PayFlowRole.PayrollOfficer,
        ];
        this.currentPayFlowRole = PayFlowRole.PayrollOfficer;
      } else if (userRole < 10) {
        this.payFlowRoleOptions = [
          PayFlowRole.BranchManager,
          PayFlowRole.Accountant,
        ];
        this.currentPayFlowRole = PayFlowRole.Accountant;
      } else if (userRole < 11) {
        this.payFlowRoleOptions = [PayFlowRole.BranchManager];
        this.currentPayFlowRole = PayFlowRole.BranchManager;
      }
    },
    updateAndMarkPayFlow(payFlow: PayFlow) {
      const updatedAndMarkedPayflow = this.markPayFlowEdited(
        payFlow,
        this.currentPayFlowRole
      );
      this.updatePayFlow(updatedAndMarkedPayflow);
    },
    async updatePayFlow(payFlow: PayFlow, noToast?: boolean) {
      if (!payFlow._id) return;
      try {
        const response = await this.payFlowService.updatePayFlow(payFlow);
        if (response._id) {
          if (!noToast) ToastService.show("PayFlow erfolgreich hinzugefügt");
          if (this.$refs.dialogAddEditPayFlowComponent) {
            (
              this.$refs.dialogAddEditPayFlowComponent as InstanceType<
                typeof DialogAddEditPayFlow
              >
            ).closeModal();
          }
          this.loadPayFlows();
        }
      } catch (error) {
        if (!noToast)
          ToastService.showError("Fehler beim updaten des PayFlows!");
        console.error("Error adding PayFlows", error);
      }
    },
  },
});
</script>
<style scoped>
.copyable {
  cursor: pointer;
}
.custom-row-spacing {
  margin-top: 2px !important; /* Ändere diesen Wert nach Bedarf */
  margin-bottom: 2px !important; /* Ändere diesen Wert nach Bedarf */
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
