// src/plugins/vuetify.ts
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, fa } from "vuetify/iconsets/fa-svg";
import { mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "../../scss/styles.scss";

const lightTheme = {
  dark: false,
  colors: {
    background: "#F5F5F5",
    surface: "#FFFFFF",
    "surface-bright": "#F9F9F9",
    "surface-light": "#E0E0E0",
    "surface-variant": "#757575",
    "on-surface-variant": "#FFFFFF",
    primary: "#002f5d",
    "primary-darken-1": "#1a3553",
    secondary: "#85bd86",
    "on-secondary": "#FFFFFF",
    "secondary-darken-1": "#577a58",
    "on-secondary-darken-1": "#FFFFFF",
    tertiary: "#f18700",
    "tertiary-darken-1": "#c06d01",
    "on-tertiary": "#FFFFFF",
    "on-tertiary-darken-1": "#FFFFFF",
    card: "#F4F7FA",
    "card-darken-1": "#E8EDF3",
    "on-card": "#000000",
    cardHover: "#EBF0F3",
    "on-cardHover": "#000000",
    cardMatch: "#C6CCD4",
    "on-cardMatch": "#000000",
    matchHover: "#E4E9F0",
    "on-matchHover": "#000000",
    cardPerfectMatch: "#bcddb5",
    "on-cardPerfectMatch": "#000000",
    perfectMatchHover: "#D0D8DE",
    "on-perfectMatchHover": "#000000",
    error: "#B71C1C",
    info: "#2196F3",
    abort: "#B71C1C",
    success: "#2E7D32",
    warning: "#FB8C00",
  },
  variables: {
    "border-color": "#2196F3",
    "border-opacity": 0.12,
    "high-emphasis-opacity": 0.87,
    "medium-emphasis-opacity": 0.6,
    "disabled-opacity": 0.38,
    "idle-opacity": 0.04,
    "hover-opacity": 0.3,
    "focus-opacity": 0.12,
    "selected-opacity": 0.08,
    "activated-opacity": 0.12,
    "pressed-opacity": 0.12,
    "dragged-opacity": 0.08,
    "theme-kbd": "#212529",
    "theme-on-kbd": "#FFFFFF",
    "theme-code": "#F5F5F5",
    "theme-on-code": "#000000",
  },
};

const darkTheme = {
  dark: true,
  colors: {
    background: "#121212",
    surface: "#1E1E1E",
    "surface-bright": "#2C2C2C",
    "surface-light": "#2E2E2E",
    "surface-variant": "#424242",
    "on-surface-variant": "#E0E0E0",
    primary: "#4A90E2",
    "primary-darken-1": "#357ABD",
    secondary: "#A5D6A7",
    "on-secondary": "#1E4620",
    "secondary-darken-1": "#81C784",
    "on-secondary-darken-1": "#1E4620",
    tertiary: "#FFA726",
    "tertiary-darken-1": "#FB8C00",
    "on-tertiary": "#4A2900",
    "on-tertiary-darken-1": "#4A2900",
    card: "#2A2A2A",
    "card-darken-1": "#202020",
    "on-card": "#FFFFFF",
    cardHover: "#333333",
    "on-cardHover": "#FFFFFF",
    cardMatch: "#002346",
    "on-cardMatch": "#FFFFFF",
    matchHover: "#001931",
    "on-matchHover": "#FFFFFF",
    cardPerfectMatch: "#56774f",
    "on-cardPerfectMatch": "#FFFFFF",
    perfectMatchHover: "#4A5E55",
    "on-perfectMatchHover": "#FFFFFF",
    error: "#EF5350",
    info: "#42A5F5",
    abort: "#EF5350",
    success: "#66BB6A",
    warning: "#FFA726",
  },
  variables: {
    "border-color": "#42A5F5",
    "border-opacity": 0.12,
    "high-emphasis-opacity": 0.87,
    "medium-emphasis-opacity": 0.6,
    "disabled-opacity": 0.38,
    "idle-opacity": 0.04,
    "hover-opacity": 0.3,
    "focus-opacity": 0.12,
    "selected-opacity": 0.08,
    "activated-opacity": 0.12,
    "pressed-opacity": 0.12,
    "dragged-opacity": 0.08,
    "theme-kbd": "#E0E0E0",
    "theme-on-kbd": "#000000",
    "theme-code": "#2E2E2E",
    "theme-on-code": "#FFFFFF",
  },
};

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      fa,
      mdi,
    },
  },

  theme: {
    defaultTheme: "light",
    themes: {
      light: lightTheme,
      dark: darkTheme,
    },
  },
});

export default vuetify;
